import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';

class TgsHomeTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        video_tgs_home: this.props.form.video_tgs_home,
      },
    };
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });

    this.props.onChange(form);
  };

  render() {
    return (
      <Grid container spacing={40} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <TextField
            label="URL do Vídeo (youtube ou vimeo)"
            value={this.state.form.video_tgs_home}
            onChange={this.handleChange('video_tgs_home')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.props.onSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default TgsHomeTab;
