import { get, post, put, transformData } from './api';

const PATH = '/users';

class UserService {
  static all() {
    return get(PATH).then(transformData);
  }

  static paramsPost(data) {
    let params = {
      data: {
        type: 'users',
        attributes: {
          ...data,
        },
      },
    };

    delete params.data.attributes.id;
    return params;
  }

  static findOne(id) {
    return get(`${PATH}/${id}`).then(transformData);
  }

  static save(data, id) {
    let params = UserService.paramsPost(data);
    return id ? put(`${PATH}/${id}`, params) : post(`${PATH}`, params);
  }
}

export { UserService };
