import { FETCHED_STORES, FETCHED_ROOMS, FETCHED_FOOD_STORES } from '../actions';

const defaultState = {
  stores: {
    data: [],
    pagination: null,
  },
  rooms: {
    data: [],
    pagination: null,
  },
  foodStores: {
    data: [],
    pagination: null,
  },
};

const storeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCHED_STORES:
      state = { ...state, stores: action.payload };
      break;

    case FETCHED_ROOMS:
      state = { ...state, rooms: action.payload };
      break;

    case FETCHED_FOOD_STORES:
      state = { ...state, foodStores: action.payload };
      break;

    default:
      break;
  }

  return state;
};

export { storeReducer };
