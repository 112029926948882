import React from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { PageService, CategoryService } from '../../service';
import { Link } from 'react-router-dom';
import Routes from '../../constants/routes';
import AddIcon from '@material-ui/icons/Add';
import PaperContent from '../../components/PaperContent';
import MultiSelect from '../../components/MultiSelect';
import ModalCategory from '../../components/ModalCategory';
import RichTextEditor from '../../components/RichTextEditor';
import ImageUpload from '../../components/ImageUpload';

const type = 'event';

class EventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      categories: [],
      error: false,
      loading: true,
      title: '',
      form: {
        title: '',
        description: '',
        categories: [],
      },
      modalCategory: false,
      categoriesOptions: [],
      refreshCategories: 0,
    };
  }

  componentDidMount() {
    let _title = '';
    if (this.props.match.params && this.props.match.params.id) {
      _title = 'Eventos > Edição';
      this.getPage(this.props.match.params.id);
    } else {
      _title = 'Eventos > Cadastro';
      this.getCategory();
    }
    this.setState({
      title: _title,
    });
  }

  async getPage(id) {
    try {
      const page = await PageService.findOne(id);
      if (page) {
        this.setState(
          {
            id: id,
            picture: page.picture_url,
          },
          () => {
            this.getCategory(page);
          }
        );
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Get sem resultado';
      }
    } catch (e) {
      alert('Tente novamente');
      console.log(e);
    }
  }

  async getCategory(page = null) {
    try {
      let categoriesOptions = await CategoryService.list(type, null, null);
      let categories = [];
      categoriesOptions = categoriesOptions.map(i => {
        const _item = { value: i.id, label: i.label };
        if (page && page.categories.length && page.categories.indexOf(i.id) !== -1) {
          categories.push(_item);
        }
        return _item;
      });

      let _form = { ...this.state.form, ...page };

      this.setState({
        categoriesOptions,
        form: { ..._form, categories },
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  handleChangePublished = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target.checked;
    this.setState({ form });
  };

  handleChangeState = name => value => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        let form = { ...this.state.form };
        form[name + '_url'] = '';
        this.setState({ form });
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      const params = this.transformParams();
      this.onSubmitClick(params);
    }
  };

  validateForm = () => {
    if (!this.state.form.title || !this.state.form.categories.length) {
      Swal({
        type: 'error',
        title: 'Oops...',
        text: 'Preencha os campos obrigatórios',
      });
      return false;
    }
    return true;
  };

  modalCategory = () => {
    this.setState({
      modalCategory: true,
    });
  };

  outputModalCategory = data => {
    this.setState({
      modalCategory: false,
    });
    if (data) {
      let _state = { ...this.state };
      _state.form.categories.push({ value: data.id, label: data.label });
      this.setState({
        refreshCategories: this.state.refreshCategories + 1,
        form: _state.form,
      });
    }
  };

  transformParams = () => {
    let params = { ...this.state.form };
    params.category_id = '';

    if (this.state.picture) {
      params.picture = this.state.picture;
    }

    if (params.categories) {
      params.category_id = params.categories.map(c => c.value);
    }

    delete params.categories;
    return params;
  };

  onSubmitClick(params) {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        PageService.savePost(params, type)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
              this.props.history.push(Routes.events);
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }
    return (
      <div>
        <ModalCategory
          type={type}
          outputModalCategory={this.outputModalCategory}
          open={this.state.modalCategory}
        />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h6" color="inherit">
              {this.state.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <Paper>
                <PaperContent>
                  <Grid container spacing={40}>
                    <Grid container item direction="column" spacing={24} xs={12} md={8}>
                      {this.props.pageError && (
                        <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                          <Typography color="error">
                            Preencha os dados e envie novamente!
                          </Typography>
                        </Grid>
                      )}

                      <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                        <TextField
                          label="Título *"
                          value={this.state.form.title || ''}
                          onChange={this.handleChange('title')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }} xs={12}>
                        <RichTextEditor
                          value={this.state.form.description}
                          onChange={this.handleChange('description')}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container>
                        <Grid item xs={8} md={11} style={{ paddingTop: 10 }}>
                          <MultiSelect
                            key={this.state.refreshCategories}
                            options={this.state.categoriesOptions}
                            value={this.state.form.categories}
                            label="Categorias *"
                            placeholder="Selecione as Categorias"
                            onChange={this.handleChange('categories')}
                          />
                        </Grid>
                        <Grid item xs={4} md={1} style={{ paddingTop: 25 }}>
                          <Button color="primary" aria-label="Add" onClick={this.modalCategory}>
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} style={{ paddingTop: 10 }}>
                        <Typography variant="subtitle1">Imagem destaque</Typography>
                        <ImageUpload
                          url={this.state.picture}
                          onChange={this.handleChangeState('picture')}
                          legend=""
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={Routes.events}>
                        <Button type="button" color="secondary">
                          Cancelar
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.form.published}
                            onChange={this.handleChangePublished('published')}
                            value={this.state.form.published}
                            color="primary"
                          />
                        }
                        label="Publicar"
                      />
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export { EventForm };
