/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';

/* Table With Selection
 *
 * Tabela usada na home, para seleção das 3 lojas ou notícias
 */

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class TableWithSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      orderBy: 'created_at',
      selected: this.props.selecteds,
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.selecteds,
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, item) => {
    let id = item.id;
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (this.state.selected.length >= 3 && selectedIndex === -1) {
      return false;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    this.props.handleSelect(newSelected);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, loading, head, data, maxSelection, pagination } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 100 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }

    return (
      <Paper className={classes.root}>
        <Table aria-labelledby="tableTitle">
          <TableBody>
            {data.map(item => {
              const isSelected = this.isSelected(item.id);
              if (isSelected) {
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, item)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={item.id}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox" style={{ width: 1 }}>
                      <Checkbox
                        icon={<StarBorderIcon />}
                        checkedIcon={<StarIcon />}
                        checked={isSelected}
                      />
                    </TableCell>
                    {head.map(column => (
                      <TableCell
                        key={column.id}
                        padding={column.disablePadding ? 'none' : 'default'}
                        numeric={column.numeric}
                      >
                        {item[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
        {maxSelection && (
          <EnhancedTableToolbar numSelected={selected.length} maxSelection={maxSelection} />
        )}

        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              data={head}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => {
                  const isSelected = this.isSelected(item.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, item)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={item.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox" style={{ width: 1 }}>
                        <Checkbox
                          icon={<StarBorderIcon />}
                          checkedIcon={<StarIcon />}
                          checked={isSelected}
                        />
                      </TableCell>
                      {head.map(column => (
                        <TableCell
                          key={column.id}
                          padding={column.disablePadding ? 'none' : 'default'}
                          numeric={column.numeric}
                        >
                          {item[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {pagination !== false && (
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </Paper>
    );
  }
}

const styles = theme => ({
  root: { width: '100%' },

  tableWrapper: { overflowX: 'auto' },
});

TableWithSelection.propTypes = {
  head: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  selecteds: PropTypes.array,
  loading: PropTypes.bool,
  maxSelection: PropTypes.number,
  handleSelect: PropTypes.func,
};

export default withStyles(styles)(TableWithSelection);
