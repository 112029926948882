import Swal from 'sweetalert2';
export default class Utils {
  static alertRemove = (Service, data, item) => {
    return new Promise((resolve, reject) => {
      Swal({
        title: 'Deseja remover este registro permanentemente?',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return Service.remove(item.id);
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
        .then(result => {
          if (result.value) {
            data.splice(data.indexOf(item), 1);
            resolve(data);
            Swal('Registro removido com sucesso.', '', 'success');
          }
        })
        .catch(() => {
          Swal('Algo deu errado. Tente novamente.', '', 'error');
          reject();
        });
    });
  };

  static filterIt = (arr, searchKey) => {
    arr = [...arr];
    return arr.filter(obj => {
      // eslint-disable-next-line array-callback-return
      return Object.keys(obj).some(key => {
        if (!Array.isArray(obj[key]) && obj[key]) {
          return obj[key]
            .toString()
            .toLowerCase()
            .includes(searchKey.toLowerCase());
        }
      });
    });
  };
}
