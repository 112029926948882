import React from 'react';
import {
  Grid,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import CarouselImages from '../../components/CarouselImages';
import ImageUpload from '../../components/ImageUpload';
import { DestaqueService } from '../../service/DestaqueService';
import Routes from '../../constants/routes';

class DestaqueForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.match.params.id,
      form: [],
      removeIds: [],
      loading: true,
    };

    this.getForm();
  }

  async getForm() {
    try {
      const res = await DestaqueService.findOne(this.state.type);
      if (res.data.length) {
        res.data.map(item => {
          item.preview = item.picture_url;
          item.preview_tablet = item.picture_tablet_url;
          item.preview_mobile = item.picture_mobile_url;
          delete item.picture_url;
          delete item.picture_tablet_url;
          delete item.picture_mobile_url;
          return item;
        });
      }
      this.setState({
        form: res.data,
        loading: false,
      });
    } catch (e) {
      alert('Tente novamente');
      console.log('destaques::form', e);
    }
  }

  handleChange = (name, index) => value => {
    let state = { ...this.state };
    state.form[index][name] = !!value.target ? value.target.value : value;
    this.setState({ form: state.form });
  };

  fileInputMultipleChange = images => {
    let files = images.target.files;
    let urls = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        urls.push({
          url: '',
          picture: files[i],
          preview:
            files[i].type === 'video/mp4'
              ? 'https://s3.us-east-2.amazonaws.com/tgs-files/sections/video.png'
              : URL.createObjectURL(files[i]),
        });
      }
    }
    let _state = { ...this.state };

    this.setState(
      {
        form: [],
      },
      () => {
        this.setState({
          form: [...urls, ..._state.form],
        });
      }
    );
  };

  onDeleteClick(item) {
    let _state = { ...this.state };

    // pode existir itens sem id(são os novos itens)
    if (item.id) {
      _state.removeIds.push(item.id);
      this.setState({
        removeIds: _state.removeIds,
      });
    }

    const _index = _state.form.indexOf(item);
    _state.form.splice(_index, 1);

    this.setState(
      {
        form: [],
      },
      () => {
        this.setState({
          form: _state.form,
        });
      }
    );
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      Swal({
        title: 'Salvando',
        onOpen: () => {
          Swal.showLoading();

          DestaqueService.save(this.state.type, this.state.form, this.state.removeIds)
            .then(response => {
              if (!response.error) {
                Swal('Sucesso', '', 'success');
                this.props.history.push(Routes.destaques);
              } else {
                Swal('Tente novamente', '', 'error');
              }
            })
            .catch(() => {
              Swal('Tente novamente', '', 'error');
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    }
  };

  validateForm = () => {
    for (let index = 0; index < this.state.form.length; index++) {
      let item = this.state.form[index];
      if (
        (!item.picture && !item.preview) ||
        (!item.picture_tablet && !item.preview_tablet) ||
        (!item.picture_mobile && !item.preview_mobile)
      ) {
        Swal({
          type: 'error',
          title: 'Oops...',
          text: 'Preencha os campos obrigatórios',
        });
        return false;
      }
    }
    return true;
  };

  render(props) {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={12}>
          <CarouselImages multiple={this.fileInputMultipleChange} accept="*" />
        </Grid>
        <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
          <Button type="button" variant="contained" color="primary" onClick={this.handleSubmit}>
            Salvar
          </Button>
        </Grid>
        {this.state.form.length > 0 &&
          this.state.form.map((item, index) => (
            <Grid item xs={12} md={12} key={item.id}>
              <Card>
                {/* <CardMedia
                    style={{ height: 200, backgroundSize: 'contain' }}
                    image={item.preview}
                    title="Contemplative Reptile"
                  /> */}
                <CardContent>
                  <Grid container spacing={16}>
                    <Grid item xs={12} md={4}>
                      <p>Desktop 2048x800px *</p>
                      <ImageUpload
                        url={item.preview}
                        rmClearBtn={true}
                        onChange={this.handleChange('picture', index)}
                        accept="*"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <p>Tablet 1200x450px *</p>
                      <ImageUpload
                        url={item.preview_tablet}
                        rmClearBtn={true}
                        onChange={this.handleChange('picture_tablet', index)}
                        accept="*"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <p>Mobile 411x400px *</p>
                      <ImageUpload
                        url={item.preview_mobile}
                        rmClearBtn={true}
                        onChange={this.handleChange('picture_mobile', index)}
                        accept="*"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    style={{ marginTop: '20px' }}
                    label="URL"
                    value={item.url || ''}
                    onChange={this.handleChange('url', index)}
                    fullWidth
                  />
                  <TextField
                    style={{ marginTop: '20px' }}
                    label="Ordem"
                    value={item.order || ''}
                    onChange={this.handleChange('order', index)}
                    fullWidth
                  />
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={this.onDeleteClick.bind(this, item)}
                  >
                    Remover
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        {this.state.form.length >= 1 && (
          <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              style={{ marginBottom: '40px' }}
              onClick={this.handleSubmit}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

export { DestaqueForm };
