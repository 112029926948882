import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
// import CarouselImages from './CarouselImages';
import Table from './Table';

class TableComponent extends React.Component {
  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {this.props.addText && (
            <Button variant="contained" color="primary" onClick={this.props.onCreateClick}>
              {this.props.addText}
            </Button>
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <CarouselImages />
        </Grid> */}
        <Grid item xs={12}>
          <Table
            loading={this.props.loading}
            title={this.props.tableTitle}
            head={this.props.tableHead}
            data={this.props.data}
            onDeleteClick={this.props.onDeleteClick}
            onEditClick={this.props.onEditClick}
          />
        </Grid>
      </Grid>
    );
  }
}

TableComponent.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  addText: PropTypes.string,
  tableTitle: PropTypes.string,
  tableHead: PropTypes.array,
  onCreateClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default TableComponent;
