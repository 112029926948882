import React from 'react';
import { withStyles } from '@material-ui/core';

const PaperContent = ({ classes, children }) => <div className={classes.root}>{children}</div>;

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
    boxSizing: 'border-box',
    width: '100%',
  },
});

export default withStyles(styles)(PaperContent);
