import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import RichTextEditor from '../../components/RichTextEditor';

class ShoppingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        title: this.props.form.title,
        description: this.props.form.description,
        address: this.props.form.address,
      },
    };
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });

    this.props.onChange(form);
  };

  render() {
    return (
      <Grid container spacing={40} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <TextField
            label="Título"
            value={this.state.form.title}
            onChange={this.handleChange('title')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Endereço"
            value={this.state.form.address}
            onChange={this.handleChange('address')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            value={this.state.form.description}
            onChange={this.handleChange('description')}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.props.onSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default ShoppingTab;
