import { combineReducers } from 'redux';
import { authReducer } from './AuthReducer';
import { storeReducer } from './StoreReducer';
import { cupomReducer } from './CupomReducer';
import { pageReducer } from './PageReducer';
import { serviceReducer } from './ServiceReducer';
import { newsletterReducer } from './NewsletterReducer';
import { shoppingReducer } from './ShoppingReducer';

export const rootReducer = combineReducers({
  authReducer,
  storeReducer,
  cupomReducer,
  pageReducer,
  serviceReducer,
  newsletterReducer,
  shoppingReducer,
});
