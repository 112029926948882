import React from 'react';
// import Swal from 'sweetalert2';
import Utils from '../../Utils';
import TableComponent from '../../components/TableComponent';
import Routes from '../../constants/routes';
import { CupomService } from '../../service';
const tableHead = [
  {
    id: 'store.name',
    label: 'Nome da loja',
  },
  {
    id: 'description',
    label: 'Descrição',
  },
  {
    id: 'validate_at',
    label: 'Validade',
  },
];
class CupomList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
    this.getPage();
  }

  async getPage() {
    try {
      const res = await CupomService.list();
      // eslint-disable-next-line array-callback-return
      res.data.map(item => {
        item.validate_at = new Date(item.validate_at).toLocaleDateString('pt-BR');
      });

      this.setState({ data: res.data, loading: false });
    } catch (e) {
      console.log(e);
    }
  }

  onCreateClick = () => {
    this.props.history.push(Routes.newCupom);
  };

  onEditClick = id => {
    this.props.history.push(Routes.editCupom.replace(':id', id));
  };

  onDeleteClick = item => {
    Utils.alertRemove(CupomService, this.state.data, item).then(data => {
      this.setState({ data });
    });
  };

  render() {
    return (
      <TableComponent
        loading={this.state.loading}
        tableTitle="Cupons"
        tableHead={tableHead}
        addText="Novo"
        data={this.state.data}
        onCreateClick={this.onCreateClick}
        onEditClick={this.onEditClick}
        onDeleteClick={this.onDeleteClick}
      />
    );
  }
}

export { CupomList };
