import axios from 'axios';
import { store } from '../state/store';

export const API =
  process.env.REACT_APP_API_BASE_URL || 'http://tgs-api-dev2.us-east-2.elasticbeanstalk.com/api';

export const transformData = response => {
  response = response.data;

  if (!!response.data) {
    if (!Array.isArray(response.data)) {
      const included = response.included;
      const { attributes, id, relationships } = response.data;

      if (included) {
        const includes = getIncludes(relationships, included);
        return { ...attributes, id, ...includes };
      }

      return { ...attributes, id };
    }

    let result = {};
    const { included, meta } = response;

    if (meta) {
      result = { ...result, pagination: meta.pagination };
    }

    let data = response.data.map(({ attributes, id, relationships }) => {
      if (included) {
        const includes = getIncludes(relationships, included);
        return { ...attributes, id, ...includes };
      }

      return { ...attributes, id };
    });

    return { ...result, data };
  }
  return response;
};

export const getIncludes = (relationships, included) => {
  let result = {};

  for (const key of Object.keys(relationships)) {
    let { data } = relationships[key];

    if (Array.isArray(data)) {
      data = data.map(({ id }) => {
        return included.find(i => i.id === id);
      });

      result[key] = data.map(({ id, attributes }) => {
        return { id, ...attributes };
      });
    } else {
      const { id, attributes } = included.find(i => i.id === data.id);
      result[key] = { id, ...attributes };
    }
  }

  return result;
};

export const axiosHeader = () => {
  const TOKEN = store.getState().authReducer.login;
  axios.defaults.headers.common['Authorization'] = TOKEN
    ? `${TOKEN.token_type} ${TOKEN.access_token}`
    : '';
};

export const get = (path, params = {}) => {
  axiosHeader();
  return axios.get(`${API}${path}`, params);
};

export const post = (path, body) => {
  axiosHeader();
  return axios.post(`${API}${path}`, body);
};

export const put = (path, body) => {
  axiosHeader();
  return axios.put(`${API}${path}`, body);
};

export const deleteObject = path => {
  axiosHeader();
  return axios.delete(`${API}${path}`);
};

export const getDownload = path => {
  const TOKEN = store.getState().authReducer.login;
  return axios({
    url: `${API}${path}`,
    method: 'get',
    withCredentials: false,
    responseType: 'blob',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: `${TOKEN.token_type} ${TOKEN.access_token}`,
    },
  });
};
