import { get, post, put, deleteObject, transformData } from './api';

const SERVICE_PATH = '/motives';

class MotivesService {
  static list(page = 0, size = 1000) {
    let params = {
      'page[size]': size,
      'page[number]': page,
    };

    return get(SERVICE_PATH, params).then(transformData);
  }

  static findOne(id) {
    return get(`${SERVICE_PATH}/${id}`).then(transformData);
  }

  // static save(store) {
  //   const path = store.id ? `${SERVICE_PATH}/${store.id}` : SERVICE_PATH;
  //   return post(path, store);
  // }

  static remove(id) {
    return deleteObject(`${SERVICE_PATH}/${id}`);
  }

  static paramsPost(type, data) {
    let params = {
      data: {
        type: type,
        attributes: {
          type,
          ...data,
        },
      },
    };

    if (data.id) {
      params.data.id = data.id;
    }

    return params;
  }

  static savePost(data, type) {
    let params = MotivesService.paramsPost(type, data);

    if (data.id) {
      delete params.data.attributes.id;
      return put(`${SERVICE_PATH}/${data.id}`, params);
    }

    return post(SERVICE_PATH, params).then(transformData);
  }
}

export { MotivesService };
