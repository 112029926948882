import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import Button from '@material-ui/core/Button';

class ImagesUpload extends React.Component {
  state = { url: '' };

  componentDidMount() {
    let { url } = this.props;
    if (url) {
      let type = url.substr(url.length - 4);
      url =
        type === '.mp4' ? 'https://s3.us-east-2.amazonaws.com/tgs-files/sections/video.png' : url;
    }

    this.setState({
      url: url,
    });
  }

  componentWillReceiveProps(next) {
    const { value } = next;

    if (value) this.handleImage(value);
  }

  clearImage = () => {
    this.handleImage('');
    this.props.onChange('');
    this.input.value = '';
  };

  handleChange = event => {
    if (!!this.props.multiple) {
      this.props.multiple(event);
    } else {
      const { size } = this.props;
      const file = event.target.files[0];

      if (file) {
        if (size && file.size > size) {
          alert('Imagem muito pesada!');
          this.input.value = '';
        } else {
          let url = '';
          if (file.type === 'video/mp4') {
            url = 'https://s3.us-east-2.amazonaws.com/tgs-files/sections/video.png';
          } else {
            url = URL.createObjectURL(file);
          }
          this.handleImage(url);
          if (this.props.onChange) {
            this.props.onChange(file);
            this.input.value = '';
          }
        }
      }
    }
  };

  handleImage = url => {
    this.setState({ url });
  };

  render() {
    const { classes, legend, accept } = this.props;

    return (
      <React.Fragment>
        <div className={classes.imageContainer}>
          <label className={classes.overlay}>
            <AddPhotoIcon />
            <input
              accept={accept ? accept : 'image/*'}
              className={classes.input}
              ref={input => (this.input = input)}
              onChange={event => this.handleChange(event)}
              type="file"
              multiple={!!this.props.multiple ? true : false}
            />
          </label>
          {this.state.url && this.props.rmClearBtn !== true && (
            <div>
              <Button
                variant="fab"
                aria-label="Delete"
                size={'small'}
                className={classes.button}
                onClick={this.clearImage}
              >
                <strong>X</strong>
              </Button>
            </div>
          )}
          <img width="123" height="123" className={classes.image} src={this.state.url} alt="" />
        </div>

        {legend && (
          <Typography variant="caption" align="center">
            {legend}
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  imageContainer: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    zIndex: 100,
    right: 0,
    transform: 'translate(50%, -50%)',
    width: 35,
    minWidth: 35,
    height: 35,
    minHeight: 35,
  },
  overlay: {
    borderRadius: 4,
    border: '2px dotted #ccc',
    background: '#DDD',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '&:hover': { borderColor: '#a5a4a4' },
  },

  input: {
    position: 'absolute',
    top: -100,
    left: 0,
    width: '100%',
    bottom: 0,
    opacity: 0,
    cursor: 'pointer',
  },

  image: {
    borderRadius: 4,
    display: 'block',
    width: '100%',
    height: 'auto',
    minWidth: '100%',
    minHeight: 100,
    position: 'relative',
    pointerEvents: 'none',
  },
});
ImagesUpload.propTypes = {
  multiple: PropTypes.func,
  rmClearBtn: PropTypes.bool,
};

export default withStyles(styles)(ImagesUpload);
