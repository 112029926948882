import { get, post, put, deleteObject } from './api';

const DESTAQUE_PATH = '/sections';

class DestaqueService {
  static getFormData(item) {
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.keys(item).map(name => {
      item[name] =
        item[name] && !!item[name].size
          ? formData.append(name, new Blob([item[name]], { type: item[name].type }))
          : formData.append(name, item[name]);
    });
    return formData;
  }

  static save(type, data, remove) {
    const promises = [];
    // eslint-disable-next-line array-callback-return
    data.map(item => {
      const id = item.id;
      item = {
        picture: item.picture ? item.picture : item.preview,
        picture_tablet: item.picture_tablet ? item.picture_tablet : item.preview_tablet,
        picture_mobile: item.picture_mobile ? item.picture_mobile : item.preview_mobile,
        url: item.url,
        order: item.order
      };

      if (!!id) {
        if (!item.picture.size) delete item.picture;
        if (!item.picture_tablet.size) delete item.picture_tablet;
        if (!item.picture_mobile.size) delete item.picture_mobile;
        promises.push(
          put(`${DESTAQUE_PATH}/${type}/upload/${id}`, DestaqueService.getFormData(item))
        );
      } else {
        promises.push(post(`${DESTAQUE_PATH}/${type}/upload`, DestaqueService.getFormData(item)));
      }
    });
    // eslint-disable-next-line array-callback-return
    remove.map(id => {
      promises.push(deleteObject(`${DESTAQUE_PATH}/${id}`));
    });
    return Promise.all(promises);
  }

  static findOne(id) {
    return get(`${DESTAQUE_PATH}/${id}/images`);
  }
}

export { DestaqueService };
