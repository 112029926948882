import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
  },
  drawerWidth: 255,
  typography: {
    useNextVariants: true,
  },
});
