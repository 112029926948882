import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { CategoryService } from '../service';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

class ModalCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      open: this.props.open,
      sending: false,
      form: {
        category: '',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
    this.setState({
      sending: false,
    });
  }

  handleClose = () => {
    this.props.outputModalCategory(null);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState(
      {
        sending: true,
      },
      () => {
        this.modalCategory();
      }
    );
  };

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  getParams = () => {
    return {
      data: {
        type: 'categories',
        attributes: {
          label: this.state.form.category,
          type: this.state.type,
        },
      },
    };
  };

  async modalCategory() {
    CategoryService.add(this.getParams()).then(res => {
      if (res) {
        this.props.outputModalCategory(res);
      } else {
        this.setState(
          {
            sending: false,
          },
          () => {
            alert('Tente novamente.');
          }
        );
      }
    });
  }

  render() {
    let btnSending = !this.state.sending ? 'Salvar' : 'Salvando...';
    return (
      <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Nova categoria</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nome"
              type="text"
              fullWidth
              onChange={this.handleChange('category')}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={this.state.sending}>
              {btnSending}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ModalCategory.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  outputModalCategory: PropTypes.func,
};

export default ModalCategory;
