import { get, post, transformData } from './api';

class HomeService {
  static getData() {
    let params = {
      'page[size]': 1000,
      'page[number]': 0,
    };

    const promisses = [];
    promisses.push(get('/pages', params).then(transformData));
    promisses.push(get('/stores', params).then(transformData));
    promisses.push(get('/featured'));
    return Promise.all(promisses);
  }
  static save(data) {
    return post('/featured', data);
  }
}

export { HomeService };
