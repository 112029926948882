import React from 'react';
import { Grid, Button, Paper, Typography, TextField, CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import { MotivesService } from '../../service';
import { Link } from 'react-router-dom';
import Routes from '../../constants/routes';
import PaperContent from '../../components/PaperContent';

const type = 'motives';

class MotivesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      error: false,
      loading: true,
      title: '',
      form: {
        name: '',
      },
    };
  }

  componentDidMount() {
    let _title = '';
    let _loading = false;
    if (this.props.match.params && this.props.match.params.id) {
      _title = 'Motivos > Editar';
      _loading = true;
      this.getPage(this.props.match.params.id);
    } else {
      _title = 'Motivos > Cadastrar';
      _loading = false;
    }
    this.setState({
      title: _title,
      loading: _loading,
    });
  }

  async getPage(id) {
    try {
      const page = await MotivesService.findOne(id);

      if (page) {
        this.setState({
          id: id,
          form: page,
          picture: page.picture_url,
          loading: false,
        });
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Get sem resultado';
      }
    } catch (e) {
      alert('Tente novamente');
      console.log(e);
    }
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  handleChangeState = name => value => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        let form = { ...this.state.form };
        form[name + '_url'] = '';
        this.setState({ form });
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      const params = this.transformParams();
      this.onSubmitClick(params);
    }
  };

  validateForm = () => {
    if (!this.state.form.name) {
      Swal({
        type: 'error',
        title: 'Oops...',
        text: 'Preencha os campos obrigatórios',
      });
      return false;
    }
    return true;
  };

  transformParams = () => {
    let params = { ...this.state.form };

    if (this.state.picture) {
      params.picture = this.state.picture;
    }

    return params;
  };

  onSubmitClick(params) {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        MotivesService.savePost(params, type)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
              this.props.history.push(Routes.motives);
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h6" color="inherit">
              {this.state.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <Paper>
                <PaperContent>
                  <Grid container spacing={40}>
                    <Grid container item direction="column" spacing={24} xs={12} md={8}>
                      {this.props.pageError && (
                        <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                          <Typography color="error">
                            Preencha os dados e envie novamente!
                          </Typography>
                        </Grid>
                      )}

                      <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                        <TextField
                          label="Título *"
                          value={this.state.form.name || ''}
                          onChange={this.handleChange('name')}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={Routes.motives}>
                        <Button type="button" color="secondary">
                          Cancelar
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export { MotivesForm };
