import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import Drawer from './Drawer';
import { isLogged } from '../../modules/authentication';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  renderAppBar() {
    return (
      <div>
        <AppBar handleDrawerOpen={this.handleDrawerOpen} drawerOpen={this.state.open} />
        <Drawer handleDrawerClose={this.handleDrawerClose} drawerOpen={this.state.open} />
      </div>
    );
  }

  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.root}>
        {isLogged() && this.renderAppBar()}
        <main className={classes.content}>
          {isLogged() && <div className={classes.toolbar} />}
          {children}
        </main>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

Navigation.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(Navigation);
