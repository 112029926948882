import React from 'react';
import { AppBar, Tabs, Tab, Paper, CircularProgress } from '@material-ui/core';
import PaperContent from '../../components/PaperContent';
import { Switch, Route, Redirect } from 'react-router-dom';
import Routes from '../../constants/routes';
import Swal from 'sweetalert2';

import { ShoppingService } from '../../service/ShoppingService';

// icons
import ShoppingIcon from '@material-ui/icons/Business';
import PublicIcon from '@material-ui/icons/Public';
import WatchIcon from '@material-ui/icons/AccessTime';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

// views
import ShoppingTab from './shoppingTab';
import SocialMediaTab from './socialMediaTab';
import ScheduleTab from './scheduleTab';
import ContactTab from './contactTab';
import CupomTab from './cupomTab';
import TgsHomeTab from './tgsHomeTab';
import TrabalheConoscoTab from './trabalheConoscoTab';

class Shopping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tab: 0,
      form: {
        title: '',
        description: '',
        address: '',
        contacts: [],
        operating_hours: [],
        social_networks: {
          facebook: '',
          instagram: '',
          youtube: '',
          spotify: '',
        },
        localization: [],
        video_tgs_home: '',
      },
    };
  }

  // Active initial tab
  componentWillMount() {
    let tab = 0;

    switch (this.props.location.pathname) {
      case Routes.shoppingSocialMedia:
        tab = 1;
        break;
      case Routes.shoppingSchedule:
        tab = 2;
        break;
      case Routes.shoppingContacts:
        tab = 3;
        break;
      case Routes.shoppingCupom:
        tab = 4;
        break;
      case Routes.shoppingTgsHome:
        tab = 5;
        break;
      case Routes.shoppingTrabalheConosco:
        tab = 6;
        break;
      default:
        tab = 0;
    }
    this.handleTabChange('', tab);
    this.getData();
  }

  async getData() {
    try {
      const form = await ShoppingService.getShopping();
      this.setState({
        form,
        loading: false,
      });
    } catch (e) {
      alert('Tente novamente.');
    }
  }

  handleTabChange = (event, tab) => {
    this.setState({ tab });
  };

  handleChange = form => {
    form = { ...this.state.form, ...form };
    this.setState({ form });
  };

  handleSubmit = () => {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        ShoppingService.save(this.state.form)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  render() {
    const { tab } = this.state;
    // if (this.state.redirect) {
    //   return <Redirect to={Routes.shoppingBase+}/>;
    // }

    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }
    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            scrollable
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="O Shopping"
              icon={<ShoppingIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingHome)}
            />
            <Tab
              label="Redes Sociais"
              icon={<PublicIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingSocialMedia)}
            />
            <Tab
              label="Horários"
              icon={<WatchIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingSchedule)}
            />
            <Tab
              label="Contatos"
              icon={<PhoneIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingContacts)}
            />
            <Tab
              label="Cupom"
              icon={<LocalPlayIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingCupom)}
            />
            <Tab
              label="Tgs Solidário"
              icon={<FavoriteIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingTgsHome)}
            />
            <Tab
              label="Trabalhe conosco"
              icon={<GroupAddIcon />}
              onChange={this.handleChange}
              onClick={() => this.props.history.push(Routes.shoppingTrabalheConosco)}
            />
          </Tabs>
        </AppBar>
        <Paper square>
          {/* {!this.state.tab} */}
          <PaperContent>
            {/* { !this.state.tab && (
              <ShoppingTab />
            )} */}
            <Switch>
              <Route exact path={Routes.shoppingHome}>
                <ShoppingTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route exact path={Routes.shoppingSocialMedia}>
                <SocialMediaTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route exact path={Routes.shoppingSchedule}>
                <ScheduleTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route exact path={Routes.shoppingContacts}>
                <ContactTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route exact path={Routes.shoppingCupom}>
                <CupomTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route exact path={Routes.shoppingTgsHome}>
                <TgsHomeTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route exact path={Routes.shoppingTrabalheConosco}>
                <TrabalheConoscoTab
                  form={this.state.form}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Redirect to={Routes.shoppingHome} />
            </Switch>
          </PaperContent>
        </Paper>
      </React.Fragment>
    );
  }
}

export default Shopping;
