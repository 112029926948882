import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Redirect } from 'react-router';
import {
  withStyles,
  Drawer,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';

// Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import StarIcon from '@material-ui/icons/Star';
import StoreIcon from '@material-ui/icons/Store';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import EventIcon from '@material-ui/icons/Event';
import FastFoodIcon from '@material-ui/icons/Fastfood';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ServicesIcon from '@material-ui/icons/RoomService';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SettingsIcon from '@material-ui/icons/Build';
import MailIcon from '@material-ui/icons/Mail';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LogoutIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import Routes from '../../constants/routes';

class DrawerClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
    };
  }
  logout = () => {
    window.localStorage.clear();
    this.setState({
      logout: true,
    });

    // this.props.history.push(Routes.login);
  };
  render() {
    const { classes, handleDrawerClose, drawerOpen, theme } = this.props;

    if (this.state.logout) {
      return <Redirect to={Routes.login} />;
    }

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List component="nav">
          <Link to={Routes.home} className={classes.listItem} title="Home">
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
          <Link to={Routes.destaques} className={classes.listItem} title="Destaques">
            <ListItem button>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary="Destaques" />
            </ListItem>
          </Link>
          <Link to={Routes.stores} className={classes.listItem} title="Lojas">
            <ListItem button>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Lojas" />
            </ListItem>
          </Link>
          <Link to={Routes.rooms} className={classes.listItem} title="Salas">
            <ListItem button>
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              <ListItemText primary="Salas" />
            </ListItem>
          </Link>
          <Link to={Routes.restaurants} className={classes.listItem} title="Restaurantes">
            <ListItem button>
              <ListItemIcon>
                <FastFoodIcon />
              </ListItemIcon>
              <ListItemText primary="Restaurantes" />
            </ListItem>
          </Link>
          <Link to={Routes.events} className={classes.listItem} title="Eventos">
            <ListItem button>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Eventos" />
            </ListItem>
          </Link>
          <Link to={Routes.blog} className={classes.listItem} title="Blog">
            <ListItem button>
              <ListItemIcon>
                <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Blog" />
            </ListItem>
          </Link>
          <Link to={Routes.TGSSolidario} className={classes.listItem} title="TGS Solidário">
            <ListItem button>
              <ListItemIcon>
                <FavoriteIcon />
              </ListItemIcon>
              <ListItemText primary="TGS Solidário" />
            </ListItem>
          </Link>
          <Link to={Routes.cupom} className={classes.listItem} title="Cupons de desconto">
            <ListItem button>
              <ListItemIcon>
                <LocalPlayIcon />
              </ListItemIcon>
              <ListItemText primary="Cupons de desconto" />
            </ListItem>
          </Link>
          <Link to={Routes.services} className={classes.listItem} title="Serviços">
            <ListItem button>
              <ListItemIcon>
                <ServicesIcon />
              </ListItemIcon>
              <ListItemText primary="Serviços" />
            </ListItem>
          </Link>
          <Link to={Routes.motives} className={classes.listItem} title="Motivos">
            <ListItem button>
              <ListItemIcon>
                <PriorityHighIcon />
              </ListItemIcon>
              <ListItemText primary="Motivos" />
            </ListItem>
          </Link>
          <Link to={Routes.users} className={classes.listItem} title="Usuários">
            <ListItem button>
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>
          </Link>
          <Link to={Routes.shopping} className={classes.listItem} title="O Shopping">
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="O Shopping" />
            </ListItem>
          </Link>
          <Link to={Routes.newsletter} className={classes.listItem} title="Newsletter">
            <ListItem button>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Newsletter" />
            </ListItem>
          </Link>
          <Link to={Routes.contactUs} className={classes.listItem} title="Contatos">
            <ListItem button>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="Contatos" />
            </ListItem>
          </Link>
          <Divider />
          <ListItem button title="Sair" onClick={this.logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

const styles = theme => ({
  drawerPaper: {
    position: 'relative',

    whiteSpace: 'nowrap',

    width: theme.drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,

      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaperClose: {
    overflowX: 'hidden',

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,

      duration: theme.transitions.duration.leavingScreen,
    }),

    width: theme.spacing.unit * 7,

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },

  listItem: { '&:hover': { textDecoration: 'none' } },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

export default withStyles(styles, { withTheme: true })(DrawerClass);
