import React from 'react';
import TableComponent from '../../components/TableComponent';
import Routes from '../../constants/routes';
import { PageService } from '../../service';
import Utils from '../../Utils';
const tableHead = [
  {
    id: 'title',
    label: 'Título',
  },
  {
    id: 'categories',
    label: 'Categorias',
  },
  {
    id: 'published',
    label: 'Status',
  },
  {
    id: 'updated_at',
    label: 'Data',
  },
];
class SolidaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
    this.getPage();
  }

  async getPage() {
    try {
      const res = await PageService.all('tgs-solidario');
      this.setState({ data: res.data.data, loading: false });
    } catch (e) {
      console.log(e);
    }
  }

  onCreateClick = () => {
    this.props.history.push(Routes.newTGSSolidario);
  };

  onEditClick = id => {
    this.props.history.push(Routes.editTGSSolidario.replace(':id', id));
  };

  onDeleteClick = item => {
    Utils.alertRemove(PageService, this.state.data, item).then(data => {
      this.setState({ data });
    });
  };

  render() {
    return (
      <TableComponent
        loading={this.state.loading}
        tableTitle="TGS Solidário"
        tableHead={tableHead}
        addText="Novo"
        data={this.state.data}
        onCreateClick={this.onCreateClick}
        onEditClick={this.onEditClick}
        onDeleteClick={this.onDeleteClick}
      />
    );
  }
}

export { SolidaryList };
