import { get, put, transformData } from './api';

const PATH = '/shopping';

class ShoppingService {
  static getShopping() {
    return get(PATH).then(transformData);
  }

  static paramsPost(data) {
    let params = {
      data: {
        type: 'shopping',
        attributes: {
          ...data,
        },
      },
    };

    delete params.data.attributes.id;
    return params;
  }

  static save(data) {
    let params = ShoppingService.paramsPost(data);
    return put(`${PATH}`, params);
  }
}

export { ShoppingService };
