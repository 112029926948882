import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from '@material-ui/core';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import Utils from '../../Utils';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      page: 0,
      rowsPerPage: 10,
      data: [],
      search: '',
      searched: false,
    };
  }

  getDataColumn(_item, _column) {
    let intraObj = _column.id.split('.');
    if (intraObj.length > 1) {
      let _obj = null;
      // eslint-disable-next-line array-callback-return
      intraObj.map(item => {
        _obj = !_obj ? _item[item] : _obj[item];
      });
      return { __html: _obj };
    }

    return { __html: _item[_column.id] };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChange = name => value => {
    this.setState({
      [name]: value.target.value,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { data } = this.props;

    if (!this.state.search) {
      this.setState({
        searched: false,
        page: 0,
      });
    } else {
      this.setState({
        searched: true,
        page: 0,
        data: Utils.filterIt(data, this.state.search),
      });
    }
  };

  render() {
    const { data } = this.state.searched ? this.state : this.props;
    const { classes, title, head } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    return (
      <Paper className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <EnhancedTableToolbar title={title} />
          {!this.props.loading && (
            <form className={classes.search} onSubmit={this.handleSubmit}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Buscar..."
                value={this.state.search || ''}
                onChange={this.handleChange('search')}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </form>
          )}
        </div>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              data={head}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              btnEdit={!!this.props.onEditClick}
              btnDelete={!!this.props.onDeleteClick}
            />
            <TableBody>
              {!this.props.loading &&
                stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      {head.map((column, index) => (
                        <TableCell
                          key={index}
                          padding={column.disablePadding ? 'none' : 'default'}
                          numeric={column.numeric}
                          // style={{width: '100%'}}
                        >
                          <span dangerouslySetInnerHTML={this.getDataColumn(item, column)} />
                        </TableCell>
                      ))}
                      {!!this.props.onEditClick && (
                        <TableCell padding="none">
                          <Button title="Editar" onClick={() => this.props.onEditClick(item.id)}>
                            <EditIcon color="action" />
                          </Button>
                        </TableCell>
                      )}
                      {!!this.props.onDeleteClick && (
                        <TableCell>
                          <Button title="Deletar" onClick={() => this.props.onDeleteClick(item)}>
                            <DeleteIcon color="action" />
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              {!this.props.loading && data.length === 0 && (
                <TableRow style={{ height: 250 }}>
                  <TableCell colSpan={this.props.head.length + 2}>
                    Nenhum registro encontrado!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {this.props.loading && (
          <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
            <CircularProgress style={{ alignSelf: 'center' }} />
          </div>
        )}

        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const styles = theme => ({
  root: { width: '100%' },

  tableWrapper: { overflowX: 'auto' },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#c3c3c3',
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});

EnhancedTable.propTypes = {
  title: PropTypes.string,
  head: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default withStyles(styles)(EnhancedTable);
