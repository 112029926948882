import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

import { ImageResize } from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: this.props.value }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ text: nextProps.value });
    }
  }

  handleChange(value) {
    this.setState({ text: value });
    this.props.onChange(value);
  }

  modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageDrop: true,
    imageResize: {
      displaySize: true,
      displayStyles: {
        zIndex: 100,
      },
      handleStyles: {
        zIndex: 100,
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };

  formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  render() {
    return (
      <ReactQuill
        value={this.state.text}
        // modules={this.modules}
        formats={this.formats}
        modules={this.modules}
        onChange={this.handleChange}
        theme="snow"
        // noWrap
      />
    );
  }
}

export default RichTextEditor;
