import React from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { UserService } from '../../service';
import Routes from '../../constants/routes';
import PaperContent from '../../components/PaperContent';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      error: false,
      loading: true,
      hiddenPass: false,
      title: '',
      form: {
        name: '',
        last_name: '',
        email: '',
        password: '',
      },
      showPassword: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.setState(
        {
          title: 'Lojas > Edição',
          hiddenPass: true,
        },
        () => {
          this.getPage(this.props.match.params.id);
        }
      );
    } else {
      this.setState({
        title: 'Lojas > Cadastro',
        hiddenPass: false,
        loading: false,
      });
    }
  }

  async getPage(id) {
    try {
      const page = await UserService.findOne(id);
      if (page) {
        let form = {
          ...this.state.form,
        };

        this.setState({
          id: id,
          loading: false,
          form: { ...form, ...page },
        });
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Get sem resultado';
      }
    } catch (e) {
      alert('Tente novamente');
      console.log(e);
    }
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      const params = this.transformParams();
      this.onSubmitClick(params);
    }
  };

  validateForm = () => {
    if (
      !this.state.form.name ||
      !this.state.form.email ||
      (!this.state.hiddenPass && !this.state.form.password)
    ) {
      Swal({
        type: 'error',
        title: 'Oops...',
        text: 'Preencha os campos obrigatórios',
      });
      return false;
    }
    return true;
  };

  transformParams = () => {
    let params = { ...this.state.form };

    if (this.props.match.params && this.props.match.params.id) {
      delete params.password;
    }

    return params;
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  onSubmitClick(params) {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        UserService.save(params, this.props.match.params.id || null)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
              this.props.history.push(Routes.users);
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h6" color="inherit">
              {this.state.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <Paper>
                <PaperContent>
                  <Grid container spacing={40}>
                    <Grid container item direction="column" spacing={24} xs={12} md={12}>
                      {this.props.pageError && (
                        <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                          <Typography color="error">
                            Preencha os dados e envie novamente!
                          </Typography>
                        </Grid>
                      )}
                      <Grid container item spacing={24}>
                        <Grid item xs={6}>
                          <TextField
                            label="Nome *"
                            value={this.state.form.name || ''}
                            onChange={this.handleChange('name')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Sobrenome"
                            value={this.state.form.last_name || ''}
                            onChange={this.handleChange('last_name')}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container item spacing={24}>
                        <Grid item xs={6}>
                          <TextField
                            label="E-mail *"
                            type="email"
                            value={this.state.form.email || ''}
                            onChange={this.handleChange('email')}
                            fullWidth
                          />
                        </Grid>
                        {!this.state.hiddenPass && (
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="adornment-password">Senha *</InputLabel>
                              <Input
                                id="adornment-password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.form.password}
                                onChange={this.handleChange('password')}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                    >
                                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// const styles = theme => ({
//   table: {
//     width: `calc(100% + ${theme.spacing.unit * 8}px)`,
//     borderSpacing: theme.spacing.unit * 4,
//     borderCollapse: 'separate',
//     margin: -theme.spacing.unit * 4,
//   },

//   deleteColumn: {
//     width: 64,
//   },
// });

export { UserForm };
