import React from 'react';
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

class ScheduleTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        contacts: this.props.form.contacts,
      },
    };
  }

  handleChange = (name, index) => value => {
    let form = { ...this.state.form };
    form.contacts[index][name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  addcontacts = () => {
    let form = { ...this.state.form };
    form.contacts.push({
      day: '',
      store: '',
      food: '',
      office: '',
    });
    this.setState({ form });
  };

  removecontacts = index => {
    let form = { ...this.state.form };
    form.contacts.splice(index, 1);
    this.setState({ form });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={40} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <table className={classes.table}>
            <thead>
              <tr>
                <td>
                  <Typography variant="subtitle1">Título</Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">Contato</Typography>
                </td>
                <td className={classes.deleteColumn} />
              </tr>
            </thead>
            <tbody>
              {this.state.form.contacts.map((item, index) => (
                <tr key={index}>
                  <td>
                    <TextField
                      placeholder="Título"
                      value={item.contactName || ''}
                      fullWidth
                      onChange={this.handleChange('contactName', index)}
                    />
                  </td>
                  <td>
                    <TextField
                      placeholder="Contato"
                      value={item.contactValue || ''}
                      fullWidth
                      onChange={this.handleChange('contactValue', index)}
                    />
                  </td>
                  <td>
                    <Button
                      color="secondary"
                      title="Deletar"
                      onClick={this.removecontacts.bind(this, index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={this.addcontacts}
                  >
                    Adicionar novo
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.props.onSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  table: {
    width: `calc(100% + ${theme.spacing.unit * 8}px)`,
    borderSpacing: theme.spacing.unit * 4,
    borderCollapse: 'separate',
    margin: -theme.spacing.unit * 4,
  },

  deleteColumn: {
    width: 64,
  },
});

export default withStyles(styles)(ScheduleTab);
