import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Table from '../../components/Table';
import { NewsletterService } from '../../service/NewsletterService';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const tableHead = [
  {
    id: 'name',
    label: 'Nome',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'created_at',
    label: 'Data de Inscrição',
  },
];

class NewsletterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      loadingDownload: false,
    };
    this.getList();
  }

  async getList() {
    try {
      const res = await NewsletterService.list();
      res.data.map(item => {
        item.created_at = new Date(item.created_at).toLocaleDateString('pt-BR');
        return item;
      });

      this.setState({
        data: res.data,
        loading: false,
      });
    } catch (e) {}
  }
  handleDownload = () => {
    this.setState({
      loadingDownload: true,
    });
    NewsletterService.download()
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'newsletter.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        this.setState({
          loadingDownload: false,
        });
      });
  };
  render() {
    return (
      <Grid container spacing={24}>
        {/* <Grid item xs={12}>
          <Typography>
            Filtrar por data: <TextField id="date" type="date" />a
            <TextField id="date" type="date" />
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Table
            title="Newsletter"
            head={tableHead}
            loading={this.state.loading}
            data={this.state.data}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <Button
              size="small"
              color="primary"
              onClick={this.handleDownload}
              disabled={this.state.loadingDownload}
            >
              <CloudDownloadIcon style={{ marginRight: 5 }} />
              {this.state.loadingDownload && <span>Baixando...</span>}
              {!this.state.loadingDownload && <span>Download</span>}
            </Button>
            {this.state.data.length} cadastros
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export { NewsletterList };
