import {
  get,
  post,
  put,
  deleteObject,
  transformData
} from './api';

const STORE_PATH = '/stores';

class StoreService {
  //food, room, store
  static all(type = 'store') {
    return get(`${STORE_PATH}/type/${type}`);
  }

  static list(items = null) {
    var url = STORE_PATH;

    if (items) {
      url += '?items_num=' + items;
    }

    return get(url).then(transformData);
  }

  static findOne(id) {
    return get(`${STORE_PATH}/${id}`).then(transformData);
  }

  static paramsPost(type, data) {
    let params = {
      data: {
        type: 'stores',
        attributes: {
          type,
          ...data,
        },
      },
    };

    if (data.id) {
      params.data.id = data.id;
    }

    return params;
  }

  static savePost(data, type) {
    let images = {
      ...data
    };
    if (data.picture && !!data.picture.size) delete data.picture;
    if (data.thumbnail && !!data.thumbnail.size) delete data.thumbnail;
    if (data.details && !!data.details.size) delete data.details;

    return new Promise((resolve, reject) => {
      let params = StoreService.paramsPost(type, data);
      StoreService.savePostWithoutImage(data, params)
        .then(
          res => {
            if (res) {
              StoreService.uploads(res.id || data.id, images)
                .then(
                  res => {
                    resolve(res);
                  },
                  () => {
                    reject();
                  }
                )
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          },
          () => {
            reject();
          }
        )
        .catch(() => {
          reject();
        });
    });
  }

  static uploads(id, images) {
    const imagesUrls = {
      picture: 'upload',
      thumbnail: 'upload-thumbnail',
      details: 'upload-details',
    };

    const promises = [];
    for (const name of Object.keys(imagesUrls)) {
      if (images[name]) {
        promises.push(StoreService.upload(id, name, imagesUrls[name], images[name]));
      }
    }

    return Promise.all(promises);
  }

  static upload(id, name, url, image) {
    const formData = new FormData();
    formData.append(name, new Blob([image]));

    return post(`${STORE_PATH}/${url}/${id}`, formData);
  }

  static savePostWithoutImage(data, params) {
    if (data.id) {
      delete params.data.attributes.id;
      return put(`${STORE_PATH}/${data.id}`, params);
    }

    return post(STORE_PATH, params).then(transformData);
  }

  static remove(id) {
    return deleteObject(`${STORE_PATH}/${id}`);
  }
}

export {
  StoreService
};