import React from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import {
  CupomService,
  StoreService
} from '../../service';
import {
  Link
} from 'react-router-dom';
import Routes from '../../constants/routes';
import PaperContent from '../../components/PaperContent';
import EventStartIcon from '@material-ui/icons/EventAvailable';
import ImageUpload from '../../components/ImageUpload';

const type = 'tickets';

class CupomForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      error: false,
      loading: true,
      title: '',
      form: {
        description: '',
        store_id: '',
        validate_at: '',
      },
      image: '',
      storeOptions: [],
    };
  }

  componentDidMount() {
    let _title = '';
    if (this.props.match.params && this.props.match.params.id) {
      _title = 'Cupons > Edição';
      this.getPage(this.props.match.params.id);
    } else {
      _title = 'Cupons > Cadastro';
      this.getCategory();
    }
    this.setState({
      title: _title,
    });
  }

  async getPage(id) {
    try {
      const page = await CupomService.findOne(id);
      if (page) {
        this.setState({
            id: id,
            picture: page.picture_url,
          },
          () => {
            this.getCategory(page);
          }
        );
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Get sem resultado';
      }
    } catch (e) {
      alert('Tente novamente');
      console.log(e);
    }
  }

  async getCategory(page = null) {
    try {
      let storeOptions = await StoreService.list(300);

      let _form = {
        ...this.state.form,
        ...page
      };

      this.setState({
        storeOptions: storeOptions.data,
        form: {
          ..._form
        },
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = name => value => {
    let form = {
      ...this.state.form
    };
    form[name] = value.target ? value.target.value : value;
    this.setState({
      form
    });
  };

  handleChangeState = name => value => {
    this.setState({
        [name]: value,
      },
      () => {
        let form = {
          ...this.state.form
        };
        form[name + '_url'] = '';
        this.setState({
          form
        });
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      const params = this.transformParams();
      this.onSubmitClick(params);
    }
  };

  validateForm = () => {
    if (!this.state.picture || !this.state.form.store_id || !this.state.form.validate_at) {
      Swal({
        type: 'error',
        title: 'Oops...',
        text: 'Preencha os campos obrigatórios',
      });
      return false;
    }
    return true;
  };

  transformParams = () => {
    let params = {
      ...this.state.form
    };

    if (this.state.picture) {
      params.picture = this.state.picture;
    }

    return params;
  };

  onSubmitClick(params) {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        CupomService.savePost(params, type)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
              this.props.history.push(Routes.cupom);
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  render() {
    if (this.state.loading) {
      return ( <
        div style = {
          {
            display: 'flex',
            justifyContent: 'center',
            height: 200
          }
        } >
        <
        CircularProgress style = {
          {
            alignSelf: 'center'
          }
        }
        /> <
        /div>
      );
    }
    return ( <
      div >
      <
      Grid container spacing = {
        24
      } >
      <
      Grid item xs = {
        12
      } >
      <
      Typography variant = "h6"
      color = "inherit" > {
        this.state.title
      } <
      /Typography> <
      /Grid> <
      Grid item xs = {
        12
      } >
      <
      form onSubmit = {
        this.handleSubmit
      } >
      <
      Paper >
      <
      PaperContent >
      <
      Grid container spacing = {
        40
      } >
      <
      Grid container item direction = "column"
      spacing = {
        24
      }
      xs = {
        12
      }
      md = {
        8
      } > {
        this.props.pageError && ( <
          Grid item style = {
            {
              flexBasis: 'auto'
            }
          }
          xs = {
            12
          } >
          <
          Typography color = "error" >
          Preencha os dados e envie novamente!
          <
          /Typography> <
          /Grid>
        )
      }

      <
      Grid item style = {
        {
          flex: 1
        }
      }
      xs = {
        12
      }
      md = {
        12
      } >
      <
      FormControl style = {
        {
          width: '100%'
        }
      } >
      <
      InputLabel htmlFor = "controlled-open-select-lojsa" > Lojas * < /InputLabel> <
      Select value = {
        this.state.form.store_id
      }
      onChange = {
        this.handleChange('store_id')
      }
      inputProps = {
        {
          name: 'age',
          id: 'controlled-open-select-lojsa',
        }
      } >
      {
        this.state.storeOptions.map(option => ( <
          MenuItem value = {
            option.id
          }
          key = {
            option.id
          } > {
            option.name
          } <
          /MenuItem>
        ))
      } <
      /Select> <
      /FormControl> <
      /Grid> <
      Grid item style = {
        {
          flex: 1
        }
      }
      xs = {
        12
      } >
      <
      TextField label = "Descrição"
      value = {
        this.state.form.description
      }
      onChange = {
        this.handleChange('description')
      }
      fullWidth multiline = {
        true
      }
      rowsMax = {
        3
      }
      /> <
      /Grid> <
      Grid item style = {
        {
          flex: 1
        }
      }
      xs = {
        12
      } >
      <
      div >
      <
      Typography variant = "subtitle1" > Validade * < /Typography> <
      TextField type = "date"
      value = {
        this.state.form.validate_at.split(' ')[0]
      }
      onChange = {
        this.handleChange('validate_at')
      }
      InputProps = {
        {
          endAdornment: < EventStartIcon / > ,
        }
      }
      /> <
      /div> <
      /Grid> <
      /Grid> <
      Grid item xs = {
        12
      }
      md = {
        4
      } >
      <
      Grid item xs = {
        12
      }
      style = {
        {
          paddingTop: 10
        }
      } >
      <
      Typography variant = "subtitle1" > Imagem destaque * < /Typography> <
      ImageUpload url = {
        this.state.picture
      }
      onChange = {
        this.handleChangeState('picture')
      }
      legend = "" /
      >
      <
      /Grid> <
      /Grid> <
      Grid item xs = {
        6
      } >
      <
      Link to = {
        Routes.cupom
      } >
      <
      Button type = "button"
      color = "secondary" >
      Cancelar <
      /Button> <
      /Link> <
      /Grid> <
      Grid item xs = {
        6
      }
      style = {
        {
          textAlign: 'right'
        }
      } >
      <
      Button type = "submit"
      color = "primary" >
      Salvar <
      /Button> <
      /Grid> <
      /Grid> <
      /PaperContent> <
      /Paper> <
      /form> <
      /Grid> <
      /Grid> <
      /div>
    );
  }
}

export {
  CupomForm
};