import React from 'react';
import { Grid, Paper, Toolbar, Typography, Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Swal from 'sweetalert2';
import TableWithSelection from '../../components/TableWithSelection';
import { HomeService } from '../../service/HomeServices';
import Utils from '../../Utils';

const tableHead = [
  {
    id: 'title',
    label: 'Título',
  },
];
const tableHeadStores = [
  {
    id: 'name',
    label: 'Nome',
  },
];

class NewsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPages: [],
      dataStores: [],
      dataFullPages: [],
      dataFullStores: [],
      selectedsPage: [],
      selectedsStore: [],
      loadingPages: true,
      loadingStores: true,
      searchNovidades: '',
      searchComercio: '',
    };

    this.getData();
  }

  getData() {
    HomeService.getData().then(res => {
      this.setState({
        selectedsPage: res[2].data.pages,
        selectedsStore: res[2].data.stores,
        dataFullPages: res[0].data,
        dataPages: res[0].data,
        dataFullStores: res[1].data,
        dataStores: res[1].data,
        loadingPages: false,
        loadingStores: false,
      });
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelectPage = value => {
    this.setState({
      selectedsPage: value,
    });
  };

  handleSelectStore = value => {
    this.setState({
      selectedsStore: value,
    });
  };

  handleSubmitNovidades = e => {
    e.preventDefault();
    this.setState({
      dataPages: this.state.searchNovidades
        ? Utils.filterIt(this.state.dataFullPages, this.state.searchNovidades)
        : [...this.state.dataFullPages],
    });
  };
  handleSubmitComercio = e => {
    e.preventDefault();
    this.setState({
      dataStores: this.state.searchComercio
        ? Utils.filterIt(this.state.dataFullStores, this.state.searchComercio)
        : [...this.state.dataFullStores],
    });
  };

  handleSubmit = () => {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        let _data = {
          pages: [...this.state.selectedsPage],
          stores: [...this.state.selectedsStore],
        };
        HomeService.save(_data)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  btnSave = () => {
    return (
      <Grid container spacing={40}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button type="button" color="primary" onClick={this.handleSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={40}>
          <Grid item xs={12} md={6}>
            <Paper>
              <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Novidades</Typography>
                {!this.state.loadingPages && (
                  <form className={classes.search} onSubmit={this.handleSubmitNovidades}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Buscar..."
                      value={this.state.searchNovidades || ''}
                      onChange={this.handleChange('searchNovidades')}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                    />
                  </form>
                )}
              </Toolbar>
              <TableWithSelection
                head={tableHead}
                data={this.state.dataPages}
                maxSelection={3}
                loading={this.state.loadingPages}
                handleSelect={this.handleSelectPage}
                selecteds={this.state.selectedsPage}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Comércios</Typography>
                {!this.state.loadingPages && (
                  <form className={classes.search} onSubmit={this.handleSubmitComercio}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Buscar..."
                      value={this.state.searchComercio || ''}
                      onChange={this.handleChange('searchComercio')}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                    />
                  </form>
                )}
              </Toolbar>
              <TableWithSelection
                head={tableHeadStores}
                data={this.state.dataStores}
                maxSelection={3}
                loading={this.state.loadingStores}
                handleSelect={this.handleSelectStore}
                selecteds={this.state.selectedsStore}
              />
            </Paper>
          </Grid>
        </Grid>
        {this.btnSave()}
      </div>
    );
  }
}

const styles = theme => ({
  root: { width: '100%' },

  tableWrapper: { overflowX: 'auto' },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#c3c3c3',
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});

NewsSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsSection);
