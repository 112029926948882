import React from 'react';
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

class ScheduleTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        operating_hours: this.props.form.operating_hours,
      },
    };
  }

  handleChange = (name, index) => value => {
    let form = { ...this.state.form };
    form.operating_hours[index][name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  addOperatingHours = () => {
    let form = { ...this.state.form };
    form.operating_hours.push({
      day: '',
      store: '',
      food: '',
      office: '',
    });
    this.setState({ form });
  };

  removeOperatingHours = index => {
    let form = { ...this.state.form };
    form.operating_hours.splice(index, 1);
    this.setState({ form });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={40} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <table className={classes.table}>
            <thead>
              <tr>
                <td>
                  <Typography variant="subtitle1">Dias</Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">Horários Lojas</Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">Horários Alimentação</Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">Horários Escritórios</Typography>
                </td>
                <td className={classes.deleteColumn} />
              </tr>
            </thead>
            <tbody>
              {this.state.form.operating_hours.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Grid container spacing={40}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="Descrição"
                          value={item.day || ''}
                          onChange={this.handleChange('day', index)}
                          fullWidth
                          required
                        />
                      </Grid>
                      {index > 1 && (
                        <Grid item style={{ flex: 1 }}>
                          <TextField
                            label="Data"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                            fullWidth
                            value={item.day_date || ''}
                            onChange={this.handleChange('day_date', index)}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </td>
                  <td>
                    <Grid container spacing={40}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="de"
                          value={item.store_open || ''}
                          fullWidth
                          onChange={this.handleChange('store_open', index)}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="às"
                          value={item.store_close || ''}
                          fullWidth
                          onChange={this.handleChange('store_close', index)}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                  </td>
                  <td>
                    <Grid container spacing={40}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="de"
                          value={item.food_open || ''}
                          fullWidth
                          onChange={this.handleChange('food_open', index)}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="às"
                          value={item.food_close || ''}
                          fullWidth
                          onChange={this.handleChange('food_close', index)}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                  </td>
                  <td>
                    <Grid container spacing={40}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="de"
                          value={item.office_open || ''}
                          fullWidth
                          onChange={this.handleChange('office_open', index)}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          label="às"
                          value={item.office_close || ''}
                          fullWidth
                          onChange={this.handleChange('office_close', index)}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </td>
                  <td>
                    {index > 1 && (
                      <Button
                        color="secondary"
                        title="Deletar"
                        onClick={this.removeOperatingHours.bind(this, index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={this.addOperatingHours}
                  >
                    Adicionar novo
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.props.onSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  table: {
    width: `calc(100% + ${theme.spacing.unit * 8}px)`,
    borderSpacing: theme.spacing.unit * 4,
    borderCollapse: 'separate',
    margin: -theme.spacing.unit * 4,
  },

  deleteColumn: {
    width: 64,
  },
});

export default withStyles(styles)(ScheduleTab);
