export const publicPath = process.env.PUBLIC_PATH || '';

const route = path => publicPath.concat(path);

export default {
  home: route('/'),
  login: route('/login'),
  stores: route('/lojas'),
  newStore: route('/lojas/novo'),
  editStore: route('/lojas/editar/:id'),
  destaques: route('/destaques'),
  editDestaque: route('/destaques/editar/:id'),
  rooms: route('/salas'),
  newRoom: route('/salas/novo'),
  editRoom: route('/salas/editar/:id'),
  events: route('/eventos'),
  newEvent: route('/eventos/novo'),
  editEvent: route('/eventos/editar/:id'),
  cupom: route('/cupons'),
  newCupom: route('/cupons/novo'),
  editCupom: route('/cupons/editar/:id'),
  restaurants: route('/restaurantes'),
  newRestaurant: route('/restaurantes/novo'),
  editRestaurant: route('/restaurantes/editar/:id'),
  blog: route('/blog'),
  newPost: route('/blog/novo'),
  editPost: route('/blog/editar/:id'),
  TGSSolidario: route('/tgs-solidario'),
  newTGSSolidario: route('/tgs-solidario/novo'),
  editTGSSolidario: route('/tgs-solidario/editar/:id'),
  services: route('/servicos'),
  newService: route('/servicos/novo'),
  editService: route('/servicos/editar/:id'),
  motives: route('/motivos'),
  newMotives: route('/motivos/novo'),
  editMotives: route('/motivos/editar/:id'),
  users: route('/usuarios'),
  newUser: route('/usuarios/novo'),
  editUser: route('/usuarios/editar/:id'),
  shopping: route('/shopping'),
  shoppingHome: route('/shopping/home'),
  shoppingSocialMedia: route('/shopping/redes-sociais'),
  shoppingSchedule: route('/shopping/horarios'),
  shoppingContacts: route('/shopping/contatos'),
  shoppingCupom: route('/shopping/cupom'),
  shoppingTgsHome: route('/shopping/tgs-home'),
  shoppingTrabalheConosco: route('/shopping/trabalhe-conosco'),
  shoppingTabs: route('/shopping/:tab'),
  newsletter: route('/newsletter'),
  contactUs: route('/contatos'),
};
