import { FETCHED_CUPONS } from '../actions';

const defaultState = {
  cupons: {
    data: [],
    pagination: null,
  },
};

const cupomReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCHED_CUPONS:
      state = { ...state, cupons: action.payload };
      break;

    default:
      break;
  }

  return state;
};

export { cupomReducer };
