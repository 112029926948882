import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, CardActions, TextField, Grid } from '@material-ui/core';
import { userLogin } from '../../../state/middleware';
import Routes from '../../../constants/routes';

class SignInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: '',
        password: '',
      },
      error: '',
      sending: false,
    };
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target.value;
    this.setState({ form });
  };

  handleSubmit = e => {
    e.preventDefault();
    // this.props.submitLogin({...this.state});
    this.setState({
      error: '',
      sending: true,
    });
    this.props
      .dispatch(userLogin(this.state.form))
      .then(res => {
        if (res) {
          this.setState({
            error: '',
            sending: false,
          });
          this.props.history.push(Routes.home);
        } else {
          this.setState({
            error: 'Tente novamente.',
            sending: false,
          });
        }
      })
      .finally(() => {
        this.setState({
          sending: false,
        });
      });
    // return this.props.dispatch(userLogin(this.state.form));
    // this.props.handleSubmit(this.props.submitLogin);
    // this.props.submitLogin(e, this.state.form);
  };

  render() {
    return (
      <Grid container spacing={16} justify="center">
        <Grid item xs={12} md={5}>
          <form onSubmit={this.handleSubmit}>
            <div>
              <div>
                {/* <Field
                  type="text"
                  name="username"
                  // component={renderInput}
                  hendleChange={this.handleChange('username')}
                  label="Usuário"
                  required
                /> */}
                <TextField
                  type="email"
                  label="Usuário"
                  onChange={this.handleChange('username')}
                  fullWidth
                  required
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <TextField
                  type="password"
                  label="password"
                  onChange={this.handleChange('password')}
                  fullWidth
                  required
                />
                {/* <Field
                  type="password"
                  name="password"
                  // component={renderInput}
                  hendleChange={this.handleChange('password')}
                  label="Senha"
                  required
                /> */}
              </div>
              <div style={{ marginTop: '20px', marginBottom: '20px', color: 'red' }}>
                {this.state.error && <strong>{this.state.error}</strong>}
              </div>
            </div>
            <CardActions>
              <Button color="primary" variant="raised" type="submit" disabled={this.state.sending}>
                {!this.state.sending ? 'Enviar' : 'Entrando...'}
              </Button>
            </CardActions>
          </form>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = ({ authReducer }) => {
  return { ...authReducer };
};
let SignIn = connect(mapStateToProps)(SignInContainer);

export { SignIn };
