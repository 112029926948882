import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';

class SocialMediaTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        social_networks: this.props.form.social_networks,
      },
    };
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form.social_networks[name] = value.target ? value.target.value : value;
    this.setState({ form });

    this.props.onChange(form);
  };

  render() {
    return (
      <Grid container spacing={40} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <TextField
            label="Facebook"
            value={this.state.form.social_networks.facebook || ''}
            onChange={this.handleChange('facebook')}
            fullWidth
            type="url"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Instagram"
            value={this.state.form.social_networks.instagram || ''}
            onChange={this.handleChange('instagram')}
            fullWidth
            type="url"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="YouTube"
            value={this.state.form.social_networks.youtube || ''}
            onChange={this.handleChange('youtube')}
            fullWidth
            type="url"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Spotify"
            value={this.state.form.social_networks.spotify || ''}
            onChange={this.handleChange('spotify')}
            fullWidth
            type="url"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.props.onSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default SocialMediaTab;
