import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Theme from './theme';
import Navigation from './components/Navigation';
import Router from './components/Router';
import { store } from './state/store';
// import 'sweetalert2/src/sweetalert2.scss';
import 'sweetalert2/dist/sweetalert2.min.css';

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={Theme}>
            <Navigation>
              <Router />
            </Navigation>
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

const styles = theme => ({
  '@global': {
    body: { margin: 0 },

    a: {
      color: theme.palette.primary.light,
      textDecoration: 'none',

      '&:hover': { textDecoration: 'underline' },
    },
  },
});

App.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
};

export default withStyles(styles)(App);
