import { get, getDownload, transformData } from './api';

const NEWSLETTER_PATH = '/newsletter';

class NewsletterService {
  static list(page = 0, size = 1000) {
    let params = {
      'page[size]': size,
      'page[number]': page,
    };

    return get(NEWSLETTER_PATH, params).then(transformData);
  }
  static download() {
    return getDownload(`${NEWSLETTER_PATH}/export`);
  }
}

export { NewsletterService };
