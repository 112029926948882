import { get, post, put, deleteObject, transformData } from './api';

const SERVICE_PATH = '/services';

class ServicesShoppingService {
  static list(page = 0, size = 1000) {
    let params = {
      'page[size]': size,
      'page[number]': page,
    };

    return get(SERVICE_PATH, params).then(transformData);
  }

  static findOne(id) {
    return get(`${SERVICE_PATH}/${id}`).then(transformData);
  }

  static save(store) {
    const path = store.id ? `${SERVICE_PATH}/${store.id}` : SERVICE_PATH;
    return post(path, store);
  }

  static remove(id) {
    return deleteObject(`${SERVICE_PATH}/${id}`);
  }

  static paramsPost(type, data) {
    let params = {
      data: {
        type: type,
        attributes: {
          type,
          ...data,
        },
      },
    };

    if (data.id) {
      params.data.id = data.id;
    }

    return params;
  }

  static savePost(data, type) {
    let images = { ...data };
    if (data.picture && !!data.picture.size) delete data.picture;
    if (data.thumbnail && !!data.thumbnail.size) delete data.thumbnail;
    if (data.details && !!data.details.size) delete data.details;

    return new Promise((resolve, reject) => {
      let params = ServicesShoppingService.paramsPost(type, data);
      // console.log(res, res.data.data.id);
      ServicesShoppingService.savePostWithoutImage(data, params)
        .then(
          res => {
            if (res) {
              ServicesShoppingService.uploads(res.id || data.id, images)
                .then(
                  res => {
                    resolve(res);
                  },
                  () => {
                    reject();
                  }
                )
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          },
          () => {
            reject();
          }
        )
        .catch(() => {
          reject();
        });
    });
  }

  static uploads(id, images) {
    const imagesUrls = {
      picture: 'upload',
      thumbnail: 'upload-thumbnail',
      details: 'upload-details',
    };

    const promises = [];
    for (const name of Object.keys(imagesUrls)) {
      if (images[name]) {
        promises.push(ServicesShoppingService.upload(id, name, imagesUrls[name], images[name]));
      }
    }

    return Promise.all(promises);
  }

  static upload(id, name, url, image) {
    const formData = new FormData();
    formData.append(name, new Blob([image]));

    return post(`${SERVICE_PATH}/${url}/${id}`, formData);
  }

  static savePostWithoutImage(data, params) {
    if (data.id) {
      delete params.data.attributes.id;
      return put(`${SERVICE_PATH}/${data.id}`, params);
    }

    return post(SERVICE_PATH, params).then(transformData);
  }
}

export { ServicesShoppingService };
