import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Routes from '../constants/routes';

// Views
// import Store from '../views/Store';
// import NewRoom from '../views/NewRoom';
// import { NewCoupon } from '../views/NewCoupon';
// import NewRestaurant from '../views/NewRestaurant';
// import SolidaryForm from '../views/SolidaryForm';
// import NewService from '../views/NewService';
import Shopping from '../modules/shopping';

//NEW
import { isLogged } from '../modules';
import { SignIn } from '../modules/authentication';
import Home from '../modules/home';
import { CupomList, CupomForm } from '../modules/cupom';
import { EventList, EventForm } from '../modules/events';
import { SolidaryList, SolidaryForm } from '../modules/solidary';
import { BlogList, BlogForm } from '../modules/blog';
import { StoreList, StoreForm } from '../modules/store';
import { DestaqueList, DestaqueForm } from '../modules/destaques';
import { RoomList, RoomForm } from '../modules/rooms';
import { FoodStoreList, FoodStoreForm } from '../modules/foodStores';
import { UserList, UserForm } from '../modules/user';
import { ServiceList, ServiceForm } from '../modules/services';
import { MotivesList, MotivesForm } from '../modules/motives';
import { NewsletterList } from '../modules/newsletter';
import { ContactUsList } from '../modules/contact-us';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogged() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const Router = () => (
  <Switch>
    <Route exact path={Routes.login} component={SignIn} />
    <PrivateRoute exact path={Routes.home} component={Home} />
    <PrivateRoute exact path={Routes.stores} component={StoreList} />
    <PrivateRoute exact path={Routes.newStore} component={StoreForm} />
    <PrivateRoute exact path={Routes.editStore} component={StoreForm} />
    <PrivateRoute exact path={Routes.destaques} component={DestaqueList} />
    <PrivateRoute exact path={Routes.editDestaque} component={DestaqueForm} />
    {/* <PrivateRoute exact path={`${Routes.editStore}/:id`} component={Store} /> */}
    <PrivateRoute exact path={Routes.rooms} component={RoomList} />
    <PrivateRoute exact path={Routes.newRoom} component={RoomForm} />
    <PrivateRoute exact path={Routes.editRoom} component={RoomForm} />
    <PrivateRoute exact path={Routes.events} component={EventList} />
    <PrivateRoute exact path={Routes.newEvent} component={EventForm} />
    <PrivateRoute exact path={Routes.editEvent} component={EventForm} />
    <PrivateRoute exact path={Routes.cupom} component={CupomList} /> */}
    <PrivateRoute exact path={Routes.newCupom} component={CupomForm} />
    <PrivateRoute exact path={Routes.editCupom} component={CupomForm} />
    <PrivateRoute exact path={Routes.restaurants} component={FoodStoreList} />
    <PrivateRoute exact path={Routes.newRestaurant} component={FoodStoreForm} />
    <PrivateRoute exact path={Routes.editRestaurant} component={FoodStoreForm} />
    <PrivateRoute exact path={Routes.blog} component={BlogList} />
    <PrivateRoute exact path={Routes.newPost} component={BlogForm} />
    <PrivateRoute exact path={Routes.editPost} component={BlogForm} />
    <PrivateRoute exact path={Routes.TGSSolidario} component={SolidaryList} />
    <PrivateRoute exact path={Routes.newTGSSolidario} component={SolidaryForm} />
    <PrivateRoute exact path={Routes.editTGSSolidario} component={SolidaryForm} />
    <PrivateRoute exact path={Routes.services} component={ServiceList} />
    <PrivateRoute exact path={Routes.newService} component={ServiceForm} />
    <PrivateRoute exact path={Routes.editService} component={ServiceForm} />
    <PrivateRoute exact path={Routes.motives} component={MotivesList} />
    <PrivateRoute exact path={Routes.newMotives} component={MotivesForm} />
    <PrivateRoute exact path={Routes.editMotives} component={MotivesForm} />
    <PrivateRoute exact path={Routes.users} component={UserList} />
    <PrivateRoute exact path={Routes.newUser} component={UserForm} />
    <PrivateRoute exact path={Routes.editUser} component={UserForm} />
    <PrivateRoute path={Routes.shoppingTabs} component={Shopping} />
    <PrivateRoute exact path={Routes.shopping} component={Shopping} />
    <PrivateRoute exact path={Routes.newsletter} component={NewsletterList} />
    <PrivateRoute exact path={Routes.contactUs} component={ContactUsList} />
  </Switch>
);

export default Router;
