import React from 'react';
import {
  Grid,
  Button,
  Paper,
  // Toolbar,
  Typography,
  TextField,
  // AppBar,
  CircularProgress,
  // FormLabel,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { StoreService, CategoryService } from '../../service';
import Routes from '../../constants/routes';
import AddIcon from '@material-ui/icons/Add';
import PaperContent from '../../components/PaperContent';
import MultiSelect from '../../components/MultiSelect';
import ModalCategory from '../../components/ModalCategory';
import RichTextEditor from '../../components/RichTextEditor';
import ImageUpload from '../../components/ImageUpload';
import DeleteIcon from '@material-ui/icons/Delete';

const type = 'store';

class StoreForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      categories: [],
      error: false,
      loading: true,
      title: '',
      form: {
        name: '',
        description: '',
        categories: [],
        operating_hours_s_s: '',
        operating_hours_d_f: '',
        localization: '',
        contacts: [],
      },
      modalCategory: false,
      categoriesOptions: [],
      refreshCategories: 0,
    };
  }

  componentDidMount() {
    let _title = '';
    if (this.props.match.params && this.props.match.params.id) {
      _title = 'Lojas > Edição';
      this.getPage(this.props.match.params.id);
    } else {
      _title = 'Lojas > Cadastro';
      this.getCategory();
    }
    this.setState({
      title: _title,
    });
  }

  async getPage(id) {
    try {
      const page = await StoreService.findOne(id);
      if (page) {
        let _operating_hours;
        try {
          _operating_hours = this.getOperatingHours(page.operating_hours);
        } catch (e) {
          _operating_hours = page.operating_hours;
        }

        let form = {
          ...this.state.form,
        };

        if (_operating_hours.length >= 1) {
          form.operating_hours_s_s = _operating_hours[0].hour;
        }

        if (_operating_hours.length === 2) {
          form.operating_hours_d_f = _operating_hours[1].hour;
        }

        this.setState(
          {
            id: id,
            thumbnail: page.thumbnail_url,
            picture: page.picture_url,
            form,
          },
          () => {
            this.getCategory(page);
          }
        );
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Get sem resultado';
      }
    } catch (e) {
      alert('Tente novamente');
      console.log(e);
    }
  }

  getOperatingHours(json) {
    let _base = [{ day: 'Segunda a sábado', hour: '' }, { day: 'Domingos', hour: '' }];

    if (json) {
      let _json = JSON.parse(json);
      if (_json.length > 0 && !!_json[0].hour) _base[0].hour = _json[0].hour;
      if (_json.length > 1 && !!_json[1].hour) _base[1].hour = _json[1].hour;
      return _base;
    }
    return _base;
  }

  async getCategory(page = null) {
    try {
      let categoriesOptions = await CategoryService.list(type, null, null);
      let categories = [];
      categoriesOptions = categoriesOptions.map(i => {
        const _item = { value: i.id, label: i.label };
        if (page && page.categories.length && page.categories.indexOf(i.id) !== -1) {
          categories.push(_item);
        }
        return _item;
      });

      let _form = { ...this.state.form, ...page };

      this.setState({
        categoriesOptions,
        form: { ..._form, categories },
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  handleChangeContacts = (name, index) => value => {
    let form = { ...this.state.form };
    form.contacts[index][name] = value.target ? value.target.value : value;
    this.setState({ form });
  };

  handleChangeState = name => value => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        let form = { ...this.state.form };
        form[name + '_url'] = '';
        this.setState({ form });
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      const params = this.transformParams();
      this.onSubmitClick(params);
    }
  };

  validateForm = () => {
    if (
      !this.state.form.name ||
      !this.state.form.localization ||
      // !this.state.form.contacts ||
      !this.state.form.categories.length
    ) {
      Swal({
        type: 'error',
        title: 'Oops...',
        text: 'Preencha os campos obrigatórios',
      });
      return false;
    }
    return true;
  };

  modalCategory = () => {
    this.setState({
      modalCategory: true,
    });
  };

  outputModalCategory = data => {
    this.setState({
      modalCategory: false,
    });
    if (data) {
      let _state = { ...this.state };
      _state.form.categories.push({ value: data.id, label: data.label });
      this.setState({
        refreshCategories: this.state.refreshCategories + 1,
        form: _state.form,
      });
    }
  };

  transformParams = () => {
    let params = { ...this.state.form };
    params.category_id = '';

    if (this.state.thumbnail) {
      params.thumbnail = this.state.thumbnail;
    }
    if (this.state.picture) {
      params.picture = this.state.picture;
    }

    if (this.state.form.operating_hours_s_s || this.state.form.operating_hours_d_f) {
      params.operating_hours = JSON.stringify(
        this.getOperatingHours(
          JSON.stringify([
            { hour: params.operating_hours_s_s },
            { hour: params.operating_hours_d_f },
          ])
        )
      );
      delete params.operating_hours_s_s;
      delete params.operating_hours_d_f;
    }

    if (params.categories) {
      params.category_id = params.categories.map(c => c.value);
    }

    delete params.categories;
    return params;
  };

  addcontacts = () => {
    let form = { ...this.state.form };
    form.contacts = !form.contacts ? [] : form.contacts;

    form.contacts.push({
      contactName: '',
      contactValue: '',
    });
    this.setState({ form });
  };

  removecontacts = index => {
    let form = { ...this.state.form };
    form.contacts.splice(index, 1);
    this.setState({ form });
  };

  onSubmitClick(params) {
    Swal({
      title: 'Salvando',
      onOpen: () => {
        Swal.showLoading();
        StoreService.savePost(params, type)
          .then(response => {
            if (!response.error) {
              Swal('Sucesso', '', 'success');
              this.props.history.push(Routes.stores);
            } else {
              Swal('Tente novamente', '', 'error');
            }
          })
          .catch(() => {
            Swal('Tente novamente', '', 'error');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 200 }}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }
    return (
      <div>
        <ModalCategory
          type={type}
          outputModalCategory={this.outputModalCategory}
          open={this.state.modalCategory}
        />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h6" color="inherit">
              {this.state.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <Paper>
                <PaperContent>
                  <Grid container spacing={40}>
                    <Grid container item direction="column" spacing={24} xs={12} md={8}>
                      {this.props.pageError && (
                        <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                          <Typography color="error">
                            Preencha os dados e envie novamente!
                          </Typography>
                        </Grid>
                      )}

                      <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                        <TextField
                          label="Nome *"
                          value={this.state.form.name || ''}
                          onChange={this.handleChange('name')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }} xs={12}>
                        <RichTextEditor
                          value={this.state.form.description}
                          onChange={this.handleChange('description')}
                        />
                      </Grid>

                      <Grid item container style={{ flex: 1 }} xs={12} sm={10} md={12}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Horário de funcionamento</Typography>
                        </Grid>
                        <Grid item xs={12} md={10} style={{ flex: 1, marginRight: '20px' }}>
                          <TextField
                            label="Segunda a sábado"
                            value={this.state.form.operating_hours_s_s}
                            onChange={this.handleChange('operating_hours_s_s')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={10} style={{ flex: 1 }}>
                          <TextField
                            label="Domingos e feriados"
                            value={this.state.form.operating_hours_d_f}
                            onChange={this.handleChange('operating_hours_d_f')}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                        <TextField
                          label="Endereço *"
                          value={this.state.form.localization || ''}
                          onChange={this.handleChange('localization')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }} xs={12}>
                        <table style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <td>
                                <Typography variant="subtitle1">Tipo</Typography>
                              </td>
                              <td>
                                <Typography variant="subtitle1">Descrição</Typography>
                              </td>
                              <td style={{ width: '64px' }} />
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.form.contacts &&
                              this.state.form.contacts.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <TextField
                                      placeholder="Título"
                                      value={item.contactName || ''}
                                      fullWidth
                                      onChange={this.handleChangeContacts('contactName', index)}
                                    />
                                  </td>
                                  <td>
                                    <TextField
                                      placeholder="Contato"
                                      value={item.contactValue || ''}
                                      fullWidth
                                      onChange={this.handleChangeContacts('contactValue', index)}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      title="Deletar"
                                      onClick={this.removecontacts.bind(this, index)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={this.addcontacts}
                                >
                                  Adicionar novo
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                      {/* <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                        <TextField
                          label="Contato *"
                          value={this.state.form.contacts || ''}
                          onChange={this.handleChange('contacts')}
                          fullWidth
                        />
                      </Grid> */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container>
                        <Grid item xs={8} md={11} style={{ paddingTop: 10 }}>
                          <MultiSelect
                            key={this.state.refreshCategories}
                            options={this.state.categoriesOptions}
                            value={this.state.form.categories}
                            label="Categorias *"
                            placeholder="Selecione as Categorias"
                            onChange={this.handleChange('categories')}
                          />
                        </Grid>
                        <Grid item xs={4} md={1} style={{ paddingTop: 25 }}>
                          <Button color="primary" aria-label="Add" onClick={this.modalCategory}>
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Imagens</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ImageUpload
                            url={this.state.thumbnail}
                            onChange={this.handleChangeState('thumbnail')}
                            legend=""
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ImageUpload
                            url={this.state.picture}
                            onChange={this.handleChangeState('picture')}
                            legend=""
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// const styles = theme => ({
//   table: {
//     width: `calc(100% + ${theme.spacing.unit * 8}px)`,
//     borderSpacing: theme.spacing.unit * 4,
//     borderCollapse: 'separate',
//     margin: -theme.spacing.unit * 4,
//   },

//   deleteColumn: {
//     width: 64,
//   },
// });

export { StoreForm };
