import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableCell, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { data, order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {data.map((row, index) => (
            <TableCell
              key={index}
              numeric={row.numeric}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                title="Sort"
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          {this.props.btnEdit && <TableCell style={{ width: 64 }} padding="none" />}
          {this.props.btnDelete && <TableCell style={{ width: 64 }} />}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  data: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  btnEdit: PropTypes.bool,
  btnDelete: PropTypes.bool,
};

export default EnhancedTableHead;
