import axios from 'axios';
import { get, post, put, deleteObject, transformData, API } from './api';

const PAGE_PATH = '/pages';

class PageService {
  static saveImage(image) {
    const formData = new FormData();
    formData.append('picture', new Blob([image]));
    return axios.post(`${API}/pictures`, formData).then(transformData);
  }

  static remove(id) {
    return deleteObject(`${PAGE_PATH}/${id}`);
  }

  static events() {
    return PageService.all(1, 'event');
  }

  static paramsPost(type, data) {
    let params = {
      data: {
        type: 'pages',
        attributes: {
          type,
          ...data,
        },
      },
    };

    if (data.id) {
      params.data.id = data.id;
    }

    return params;
  }

  static eventsCreate(data) {
    let params = PageService.paramsPost('page', data);
    return post(PAGE_PATH, params).then(transformData);
  }

  static savePost(data, type = 'post') {
    let images = { ...data };
    if (data.picture && !!data.picture.size) delete data.picture;
    if (data.thumbnail && !!data.thumbnail.size) delete data.thumbnail;
    if (data.details && !!data.details.size) delete data.details;

    return new Promise((resolve, reject) => {
      let params = PageService.paramsPost(type, data);
      // console.log(res, res.data.data.id);
      PageService.savePostWithoutImage(data, params)
        .then(
          res => {
            if (res) {
              PageService.uploads(res.id || data.id, images)
                .then(
                  res => {
                    resolve(res);
                  },
                  () => {
                    reject();
                  }
                )
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          },
          () => {
            reject();
          }
        )
        .catch(() => {
          reject();
        });
    });
  }

  static uploads(id, images) {
    const imagesUrls = {
      picture: 'upload',
      thumbnail: 'upload-thumbnail',
      details: 'upload-details',
    };

    const promises = [];
    for (const name of Object.keys(imagesUrls)) {
      if (images[name]) {
        promises.push(PageService.upload(id, name, imagesUrls[name], images[name]));
      }
    }

    return Promise.all(promises);
  }

  static upload(id, name, url, image) {
    const formData = new FormData();
    formData.append(name, new Blob([image]));

    return post(`${PAGE_PATH}/${url}/${id}`, formData);
  }

  static savePostWithoutImage(data, params) {
    if (data.id) {
      delete params.data.attributes.id;
      return put(`${PAGE_PATH}/${data.id}`, params);
    }

    return post(PAGE_PATH, params).then(transformData);
  }

  static publishPost(data) {
    data.published = true;
    return PageService.savePost(data);
  }

  static findOne(id) {
    return get(`${PAGE_PATH}/${id}`).then(transformData);
  }

  static all(type = 'post') {
    return get(`${PAGE_PATH}/type/${type}`);
  }
}

export { PageService };
