import {
  FETCHED_EVENTS,
  CREATED_EVENTS,
  FETCHED_POSTS_SOLIDARY,
  FETCHED_POSTS,
  CREATED_POSTS,
  FETCHED_PAGE,
  CREATE_POST_ERROR,
  PAGE_START_LOADING,
  PAGE_FINISH_LOADING,
} from '../actions';

const defaultState = {
  loading: false,
  page: null,
  pageError: false,
  events: {
    data: [],
    pagination: null,
  },
  postsSolidary: {
    data: [],
    pagination: null,
  },
  posts: {
    data: [],
    pagination: null,
  },
};

const pageReducer = (state = defaultState, action) => {
  state.pageError = false;

  switch (action.type) {
    case PAGE_START_LOADING:
      state = { ...state, loading: true };
      break;

    case FETCHED_EVENTS:
      state = { ...state, events: action.payload, loading: false };
      break;

    case CREATED_EVENTS:
      state = { ...state, events: action.payload };
      break;

    case FETCHED_POSTS_SOLIDARY:
      state = { ...state, postsSolidary: action.payload, loading: false };
      break;

    case FETCHED_POSTS:
      state = { ...state, posts: action.payload, loading: false };
      break;

    case CREATED_POSTS:
      state = { ...state, posts: action.payload };
      break;

    case FETCHED_PAGE:
      state = { ...state, page: action.payload };
      break;

    case PAGE_FINISH_LOADING:
      state = { ...state, loading: false };
      break;

    case CREATE_POST_ERROR:
      state = { ...state, pageError: true };
      break;

    default:
      break;
  }

  return state;
};

export { pageReducer };
