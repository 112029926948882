import React from 'react';
import TableComponent from '../../components/TableComponent';
import Routes from '../../constants/routes';
import { UserService } from '../../service';
import Utils from '../../Utils';
const tableHead = [
  {
    id: 'name',
    label: 'Nome',
  },
  {
    id: 'Sobrenome',
    label: 'last_name',
  },
  {
    id: 'email',
    label: 'E-mail',
  },
];
class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
    this.getPage();
  }

  async getPage() {
    try {
      const res = await UserService.all();
      this.setState({ data: res.data, loading: false });
    } catch (e) {
      console.log(e);
    }
  }

  onCreateClick = () => {
    this.props.history.push(Routes.newUser);
  };

  onEditClick = id => {
    this.props.history.push(Routes.editUser.replace(':id', id));
  };

  onDeleteClick = item => {
    Utils.alertRemove(UserService, this.state.data, item).then(data => {
      this.setState({ data });
    });
  };

  render() {
    return (
      <TableComponent
        loading={this.state.loading}
        tableTitle="Usuários"
        tableHead={tableHead}
        addText="Novo"
        data={this.state.data}
        onCreateClick={this.onCreateClick}
        onEditClick={this.onEditClick}
        // onDeleteClick={this.onDeleteClick}
      />
    );
  }
}

export { UserList };
