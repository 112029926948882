import { createAction } from 'redux-actions';

export const PAGE_START_LOADING = 'PAGE_START_LOADING';
export const PAGE_FINISH_LOADING = 'PAGE_FINISH_LOADING';

export const FETCHED_EVENTS = 'FETCHED_EVENTS';
export const CREATED_EVENTS = 'CREATED_EVENTS';

export const FETCHED_POSTS_SOLIDARY = 'FETCHED_POSTS_SOLIDARY';

export const FETCHED_POSTS = 'FETCHED_POSTS';
export const FETCHED_PAGE = 'FETCHED_PAGE';
export const CREATED_POSTS = 'CREATED_POSTS';
export const DELETED_POST = 'DELETED_POST';
export const CREATE_POST_ERROR = 'CREATE_POST_ERROR';

export const pageStartLoading = createAction(PAGE_START_LOADING, data => data);
export const pageFinishLoading = createAction(PAGE_START_LOADING, data => data);

export const fetchedEvents = createAction(FETCHED_EVENTS, data => data);
export const createdEvents = createAction(CREATED_EVENTS, data => data);
export const createdPosts = createAction(CREATED_POSTS, data => data);

export const fetchedPostsSolidary = createAction(FETCHED_POSTS_SOLIDARY, data => data);
export const fetchedPosts = createAction(FETCHED_POSTS, data => data);
export const fetchedPage = createAction(FETCHED_PAGE, data => data);

export const deletedPost = createAction(DELETED_POST, data => data);
export const createPostError = createAction(CREATE_POST_ERROR, data => data);
