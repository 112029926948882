import { store } from '../../state/store';
import { loginSuccess } from '../../state/actions';
export function isLogged() {
  const token = JSON.parse(window.localStorage.getItem('token'));
  if (token) {
    store.dispatch(loginSuccess(token));
    return true;
  } else {
    return false;
  }
}
