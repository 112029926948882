import { post, transformData } from './api';

const AUTH_PATH = '/login';

class AuthService {
  static login(username, password) {
    const params = {
      data: {
        type: 'login',
        attributes: {
          login: username,
          password: password,
        },
      },
    };

    return post(AUTH_PATH, params).then(transformData);
  }
}

export { AuthService };
