import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Toolbar, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

let EnhancedTableToolbar = props => {
  const { title, numSelected, maxSelection, classes } = props;

  return (
    <React.Fragment>
      {title && (
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              {title}
            </Typography>
          </div>
          <div className={classes.spacer} />
        </Toolbar>
      )}
      <Toolbar className={classes.selectionsToolbar}>
        <Typography color="inherit" variant="subtitle1">
          {numSelected} item de {maxSelection}
        </Typography>

        {/* <Button color="secondary">Remover destaque</Button> */}
      </Toolbar>
    </React.Fragment>
  );
};

const styles = theme => ({
  root: { paddingRight: theme.spacing.unit },

  selectionsToolbar: {
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },

  spacer: { flex: '1 1 100%' },

  actions: { color: theme.palette.text.secondary },

  title: { flex: '0 0 auto' },
});

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
  maxSelection: PropTypes.number,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

export default withStyles(styles)(EnhancedTableToolbar);
