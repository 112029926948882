import { LOGIN_SUCCESS } from '../actions';

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      state = { ...state, login: action.payload };
      window.localStorage.setItem('token', JSON.stringify(action.payload));
      break;

    default:
      break;
  }

  return state;
};

export { authReducer };
