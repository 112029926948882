import { CategoryService } from '../../service';
import { fetchedCategories } from '../actions';

export const fetchCategories = (page, size) => {
  return async dispatch => {
    try {
      const response = await CategoryService.list(page, size);
      dispatch(fetchedCategories(response));
    } catch (e) {
      //TODO://tratar erro
      console.log(e);
    }
  };
};
