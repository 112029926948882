import React from 'react';
import TableComponent from '../../components/TableComponent';
import Routes from '../../constants/routes';
const tableHead = [
  {
    id: 'title',
    label: 'Título',
  },
];

const data = [
  {
    id: 'home',
    title: 'Página inicial',
  },
  {
    id: 'shopping',
    title: 'O Shopping',
  },
  {
    id: 'store',
    title: 'Lojas',
  },
  {
    id: 'food',
    title: 'Restaurantes',
  },
  {
    id: 'event',
    title: 'Eventos',
  },
  {
    id: 'room',
    title: 'Salas',
  },
  {
    id: 'services',
    title: 'Serviços',
  },
  {
    id: 'tickets',
    title: 'Cupons',
  },
  {
    id: 'post',
    title: 'Blog',
  },
  {
    id: 'tgs-solidario',
    title: 'TGS Solidário',
  },
  {
    id: 'cinema',
    title: 'Cinema',
  },
  {
    id: 'trabalhe-conosco',
    title: 'Trabalhe conosco',
  },
];
class DestaqueList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data,
    };
  }

  onEditClick = id => {
    this.props.history.push(Routes.editDestaque.replace(':id', id));
  };

  render() {
    return (
      <TableComponent
        loading={this.state.loading}
        tableTitle="Destaques"
        tableHead={tableHead}
        data={this.state.data}
        onEditClick={this.onEditClick}
      />
    );
  }
}

export { DestaqueList };
