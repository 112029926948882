import { get, transformData } from './api';

const NEWSLETTER_PATH = '/contact-us';

class ContactUsService {
  static list(page = 0, size = 1000) {
    let params = {
      'page[size]': size,
      'page[number]': page,
    };

    return get(NEWSLETTER_PATH, params).then(transformData);
  }
}

export { ContactUsService };
