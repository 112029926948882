import React from 'react';
import TableComponent from '../../components/TableComponent';
import Routes from '../../constants/routes';
import { StoreService } from '../../service';
import Utils from '../../Utils';
const tableHead = [
  {
    id: 'name',
    label: 'Nome',
  },
  {
    id: 'categories',
    label: 'Categorias',
  },
  {
    id: 'localization',
    label: 'Endereço',
  },
];
class RoomList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
    this.getPage();
  }

  async getPage() {
    try {
      const res = await StoreService.all('room');
      this.setState({ data: res.data.data, loading: false });
    } catch (e) {
      console.log(e);
    }
  }

  onCreateClick = () => {
    this.props.history.push(Routes.newRoom);
  };

  onEditClick = id => {
    this.props.history.push(Routes.editRoom.replace(':id', id));
  };

  onDeleteClick = item => {
    Utils.alertRemove(StoreService, this.state.data, item).then(data => {
      this.setState({ data });
    });
  };

  render() {
    return (
      <TableComponent
        loading={this.state.loading}
        tableTitle="Salas"
        tableHead={tableHead}
        addText="Novo"
        data={this.state.data}
        onCreateClick={this.onCreateClick}
        onEditClick={this.onEditClick}
        onDeleteClick={this.onDeleteClick}
      />
    );
  }
}

export { RoomList };
