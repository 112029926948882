import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Toolbar, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

let EnhancedTableToolbar = props => {
  const { title, classes } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {title}
        </Typography>
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

const styles = theme => ({
  root: { paddingRight: theme.spacing.unit },

  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },

  spacer: { flex: '1 1 100%' },

  actions: { color: theme.palette.text.secondary },

  title: { flex: '0 0 auto' },
});

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTableToolbar);
