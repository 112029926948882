import { get, post, transformData } from './api';

const CATEGORY_PATH = '/categories';

class CategoryService {
  static list(type = 'post', page = 1, size = 10000) {
    let params = {
      'page[size]': size,
      'page[number]': page,
    };

    return get(`${CATEGORY_PATH}/${type}`, params).then(transformData);
  }

  static add(body) {
    return post(CATEGORY_PATH, body).then(transformData);
  }
}

export { CategoryService };
