import { FETCHED_SERVICES } from '../actions';

const defaultState = {
  services: {
    data: [],
    pagination: null,
  },
};

const serviceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCHED_SERVICES:
      state = { ...state, services: action.payload };
      break;

    default:
      break;
  }

  return state;
};

export { serviceReducer };
