import React from 'react';
import PropTypes from 'prop-types';
import ImageUpload from '../components/ImageUpload';
import { Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import PaperContent from './PaperContent';

class CarouselImages extends React.Component {
  render() {
    return (
      <Paper>
        <Toolbar>
          <Typography variant="h6">Imagens de Destaque</Typography>
        </Toolbar>
        <PaperContent>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="caption">Tamanho recomendado: 2048 x 800 px</Typography>
            </Grid>
            <Grid item xs={2}>
              <ImageUpload
                multiple={!!this.props.multiple ? this.props.multiple : false}
                accept={this.props.accept}
              />
            </Grid>
          </Grid>
        </PaperContent>
      </Paper>
    );
  }
}

CarouselImages.propTypes = {
  multiple: PropTypes.func,
  accept: PropTypes.string,
};

export default CarouselImages;
