import { get, post, put, deleteObject, transformData } from './api';

const CUPOM_PATH = '/tickets';

// class CupomService {
//   static list(page = 0, size = 1000) {
//     let params = {
//       'page[size]': size,
//       'page[number]': page,
//     };

//     return get(CUPOM_PATH, params).then(transformData);
//   }

//   static get(id) {
//     return get(`${CUPOM_PATH}/${id}`);
//   }

//   static save(cupom) {
//     const path = cupom.id ? `${CUPOM_PATH}/${cupom.id}` : CUPOM_PATH;
//     return post(path, cupom);
//   }
// }

// export { CupomService };

// import { get, post, put, deleteObject, transformData } from './api';

class CupomService {
  static list(page = 0, size = 1000) {
    let params = {
      'page[size]': size,
      'page[number]': page,
    };

    return get(CUPOM_PATH, params).then(transformData);
  }

  static findOne(id) {
    return get(`${CUPOM_PATH}/${id}`).then(transformData);
  }

  static paramsPost(type, data) {
    let params = {
      data: {
        type: 'tickets',
        attributes: {
          type,
          ...data,
        },
      },
    };

    if (data.id) {
      params.data.id = data.id;
    }

    return params;
  }

  static savePost(data, type) {
    let images = { ...data };
    if (data.picture && !!data.picture.size) delete data.picture;
    if (data.thumbnail && !!data.thumbnail.size) delete data.thumbnail;
    if (data.details && !!data.details.size) delete data.details;

    return new Promise((resolve, reject) => {
      let params = CupomService.paramsPost(type, data);
      CupomService.savePostWithoutImage(data, params)
        .then(
          res => {
            if (res) {
              CupomService.uploads(res.id || data.id, images)
                .then(
                  res => {
                    resolve(res);
                  },
                  () => {
                    reject();
                  }
                )
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          },
          () => {
            reject();
          }
        )
        .catch(() => {
          reject();
        });
    });
  }

  static uploads(id, images) {
    const imagesUrls = {
      picture: 'upload',
      thumbnail: 'upload-thumbnail',
      details: 'upload-details',
    };

    const promises = [];
    for (const name of Object.keys(imagesUrls)) {
      if (images[name]) {
        promises.push(CupomService.upload(id, name, imagesUrls[name], images[name]));
      }
    }

    return Promise.all(promises);
  }

  static upload(id, name, url, image) {
    const formData = new FormData();
    formData.append(name, new Blob([image]));

    return post(`${CUPOM_PATH}/${url}/${id}`, formData);
  }

  static savePostWithoutImage(data, params) {
    if (data.id) {
      delete params.data.attributes.id;
      return put(`${CUPOM_PATH}/${data.id}`, params);
    }

    return post(CUPOM_PATH, params).then(transformData);
  }

  static remove(id) {
    return deleteObject(`${CUPOM_PATH}/${id}`);
  }
}

export { CupomService };
