import React from 'react';
import { Grid, Button, FormControlLabel, Switch } from '@material-ui/core';

class CupomTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        show_tickets_nav: this.props.form.show_tickets_nav,
      },
    };
  }

  handleChange = name => value => {
    let form = { ...this.state.form };
    form[name] = value.target.checked;
    this.setState({ form });

    this.props.onChange(form);
  };

  render() {
    return (
      <Grid container spacing={40} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.form.show_tickets_nav}
                onChange={this.handleChange('show_tickets_nav')}
                value={this.state.form.show_tickets_nav}
                color="primary"
              />
            }
            label="Habilitar - Cupons de desconto"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.props.onSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default CupomTab;
