import React from 'react';
import NewsSection from './NewsSection';

class Home extends React.Component {
  render() {
    return <NewsSection />;
  }
}

export default Home;
