import { FETCHED_NEWSLETTER_USERS } from '../actions';

const defaultState = {
  newsletterUsers: {
    data: [],
    pagination: null,
  },
};

const newsletterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCHED_NEWSLETTER_USERS:
      state = { ...state, newsletterUsers: action.payload };
      break;

    default:
      break;
  }

  return state;
};

export { newsletterReducer };
