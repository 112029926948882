import { AuthService } from '../../service';
import { loginSuccess } from '../actions';

export const userLogin = data => {
  return async dispatch => {
    try {
      const response = await AuthService.login(data.username, data.password);
      dispatch(loginSuccess(response));
      return true;
    } catch (e) {
      return false;
    }
  };
};
